import React from 'react';
import { parse } from 'node-html-parser';
import {
  Text,
  RichText,
  Image,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import addRegToDevice from './../../utilities/addRegToDevice';

function showModal(e) {
  e.preventDefault();
  let modal = document.getElementsByClassName('modal')[0];
  modal.classList.add('is-active');
  let html = document.documentElement;
  html.classList.add('is-clipped');
}

function strip(html) {
  return parse(html).text || '';
}

class DevicesRangeListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isActiveDevice: false };
    this.toggleClass = this.toggleClass.bind(this);
  }

  componentDidMount() {
    this.setState({
      isActiveDevice: this.props.params.isActiveOnLoad,
    });
  }

  toggleClass() {
    const { isActiveDevice } = this.state;
    const currentState = isActiveDevice;
    this.setState({
      isActiveDevice: !currentState,
    });
  }

  render() {
    const { fields, params, rendering, isSingleChild } = this.props;
    const { isActiveDevice } = this.state;
    const {
      deviceFamily,
      deviceName,
      subtitle,
      releaseYear,
      productLength,
      internalLink,
      image,
    } = fields;
    const {
      openModal,
      trademarkRequired,
      hasMultipleChildren,
      isChild,
    } = params;

    // update the heading value
    if (deviceName) {
      deviceName.value = addRegToDevice(
        deviceName.value,
        trademarkRequired,
      );
    }

    if (deviceFamily) {
      deviceFamily.value = addRegToDevice(
        deviceFamily.value,
        trademarkRequired,
      );
    }

    if (isChild === 'true' && isSingleChild) {
      return (
        <div className="device__link">
          <div className="device device__slim">
            <div className="device__content">
              <RichText
                tag="div"
                className="device__subtitle"
                field={deviceName}
              />
              <RichText
                className="device__details"
                field={releaseYear}
              />
            </div>
          </div>
        </div>
      );
    }

    if (isChild === 'true') {
      return (
        <Link
          className="device__link"
          data-ecategory="Select Device"
          data-elabel={strip(deviceName.value.replace('&nbsp;', ' '))}
          to={`${internalLink.value.href}`}
        >
          <div className="device device__slim">
            <div className="device__content">
              <RichText
                tag="div"
                className="device__subtitle"
                field={deviceName}
              />
              <RichText
                className="device__details"
                field={releaseYear}
              />
            </div>
          </div>
        </Link>
      );
    }

    if (hasMultipleChildren === 'true') {
      return (
        <article className="column">
          <div
            className={`device device--accordion ${
              isActiveDevice ? 'is-active' : ''
            }`}
          >
            <button
              className="device__family"
              type="button"
              onClick={this.toggleClass}
            >
              <figure className="device__image">
                <Image field={image} />
              </figure>
              <div className="device__content">
                <RichText
                  tag="h3"
                  className="title is-5"
                  field={deviceFamily}
                />
                <Text
                  tag="div"
                  className="device__subtitle"
                  field={subtitle}
                />
                <RichText
                  className="device__details"
                  field={productLength}
                />
              </div>
            </button>

            {this.props.fields.devices.map((element) => {
              return (
                <Link
                  className="device__link"
                  data-ecategory="Select Device"
                  data-elabel={element.fields.deviceName.value}
                  to={element.fields.internalLink.value.href}
                  key={element.id}
                >
                  <div className="device device__slim">
                    <div className="device__content">
                      <div className="device__subtitle">
                        {element.fields.deviceName.value}
                      </div>
                      <div className="device__details">
                        {element.fields.releaseYear.value}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </article>
      );
    }

    return (
      <article className="column">
        {/* This is the mobile version where the device is wrapped in a link */}
        {internalLink && (
          <Link
            className={`${
              openModal === 'true' ? '' : 'is-hidden-desktop'
            }`}
            data-ecategory="Select Device"
            data-elabel={strip(
              deviceFamily.value.replace('&nbsp;', ' '),
            )}
            onClick={openModal === 'true' ? showModal : undefined}
            to={`/${internalLink.value.href}`}
          >
            <div
              className={`device ${
                openModal === 'true' ? 'device--help' : ''
              }`}
            >
              <div className="device__family">
                <figure className="device__image">
                  <Image field={image} />
                </figure>
                <div className="device__content">
                  {deviceName?.value ? (
                    <>
                      <RichText
                        tag="h3"
                        className="title is-5"
                        field={deviceName}
                      />
                    </>
                  ) : (
                    <RichText
                      tag="h3"
                      className="title is-5 "
                      field={deviceFamily}
                    />
                  )}

                  <Text
                    tag="div"
                    className="device__subtitle"
                    field={subtitle}
                  />
                  <RichText
                    className="device__details"
                    field={releaseYear}
                  />
                </div>
              </div>
              {/* Not all content items have child links (placeholders) eg, the help card */}
              {rendering.placeholders && (
                <Placeholder
                  rendering={rendering}
                  name="jss-DeviceChildren"
                  isSingleChild={true}
                  renderEach={(component, index) => (
                    <React.Fragment key={index}>
                      {component}
                    </React.Fragment>
                  )}
                />
              )}
            </div>
          </Link>
        )}
        {/* This is the desktop version where the link is inside the device category block */}
        {internalLink && openModal !== 'true' && (
          <div
            className={`device is-hidden-touch ${
              openModal === 'true' ? 'device--help' : ''
            }`}
          >
            <div className="device__family">
              <figure className="device__image">
                <Image field={image} />
              </figure>
              <div className="device__content">
                <RichText
                  tag="h3"
                  className="title is-5"
                  field={deviceFamily}
                />
                <Text
                  tag="div"
                  className="device__subtitle"
                  field={subtitle}
                />
                <RichText
                  className="device__details"
                  field={releaseYear}
                />
              </div>
            </div>
            {/* Not all content items have child links (placeholders) eg, the help card */}
            {rendering.placeholders && (
              <Link
                className="device__link"
                data-ecategory="Select Device"
                data-elabel={
                  fields.devices[0].fields.deviceName.value
                }
                to={fields.devices[0].fields.internalLink.value.href}
              >
                <div className="device device__slim">
                  <div className="device__content">
                    <div className="device__subtitle">
                      {fields.devices[0].fields.deviceName.value}
                    </div>
                    <div className="device__details">
                      {fields.devices[0].fields.releaseYear.value}
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </div>
        )}
      </article>
    );
  }
}

export default DevicesRangeListing;
