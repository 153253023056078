import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

const ArticleIssueSection = (props) => {
  const { fields, params, rendering } = props;
  return (
    <div className={params.wrapperClass}>
      <div className="hero-links">
        <Link
          data-ecategory="Hero Links"
          to={`${fields.internalLink.value.href}`}
          className="is-hidden-tablet button is-rounded include-icon-before on-white"
        >
          {fields.internalLink.value.text}
        </Link>
      </div>
      <Placeholder
        rendering={rendering}
        name="jss-ArticleIssueSection"
        renderEach={(component, index) => {
          return (
            <React.Fragment key={index}>{component}</React.Fragment>
          );
        }}
      />
    </div>
  );
};

ArticleIssueSection.propTypes = {
  fields: PropTypes.shape({
    /** Description of prop "heading". */
    internalLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  }),
  params: PropTypes.shape({
    wrapperClass: PropTypes.string,
  }),
};

export default ArticleIssueSection;
