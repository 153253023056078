import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';

import config from './../../common/config';
import {
  authenticationService,
  environment,
} from './../../_services';

import ErrorScreen from './../custom/ErrorScreen/ErrorScreen';
import construction from './../../assets/graphics/construction@2x.png';
import errorImg from './../../assets/graphics/error-img@2x.png';

const { country, lang } = config;
const baseURL = config.baseURL[environment];
const { CIM_RecipientLogout } = config.authenticate[environment];

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorType: null,
      errorStatus: null,
      logout: false,
    };

    this.handleResult = this.handleResult.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    // redirect to service requests if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push(`/service-request`);
    }
  }

  handleLogout(e) {
    e.preventDefault();
    this.setState({
      logout: true,
    });
    const logoutURL = baseURL + country + '/' + lang + '/home';
    // if authenticated but not authorised for SR
    // user session never created in app (no token exists)
    // so we can call the SF logout directly and redirect user to home page
    window.location.replace(
      `${CIM_RecipientLogout}?logoutURL=${logoutURL}`,
    );
  }

  componentDidMount() {
    const { code, callbackError } = this.props.match.params;

    // I have a code, call the endpoint and return token
    if (code) {
      authenticationService.login(code).then(
        user => {
          if (!user) {
            this.handleResult();
          } else {
            const { from } = this.props.location.state || {
              from: {
                pathname: `/service-request`,
              },
            };
            this.props.history.push(from);
          }
        },
        error => {
          this.handleResult();
        },
      );
    }

    // code not returned, received an error code instead
    if (callbackError) {
      this.handleResult();
    }
  }

  handleResult() {
    this.setState({
      error: true,
    });
  }

  render() {
    const { code, callbackError } = this.props.match.params;

    const {
      errorText,
      errorTitle,
      errorGenericText,
      errorGenericTitle,
      linkText,
      logoutText,
    } = this.props.fields;

    const errorObj = {
      title:
        this.state.errorType == null
          ? errorTitle.value
          : errorGenericTitle.value,
      text:
        this.state.errorType == null
          ? errorText.value
          : errorGenericText.value,
    };

    if (!callbackError && !code) {
      return <Redirect to={`/`} />;
    }

    if (this.state.error) {
      return (
        <div className="columns is-centered">
          <div className="column is-10-desktop">
            <ErrorScreen
              image={
                this.state.errorType == null ? construction : errorImg
              }
              chat={this.props.params.chat}
              errorStatus={this.state.errorStatus}
              onlineSupport={this.props.params.onlineSupport}
              text={errorObj}
              showBottomBorder={true}
            />
            <br />
            <br />
            <Link
              to="/home"
              className="sdw__button sdw__button--cochlear-yellow sdw__button-back"
            >
              {linkText.value}
            </Link>
            <a
              href="/"
              onClick={this.handleLogout}
              className={`sdw__button sdw__button--logout btn--outline-purple has-spinner ${
                this.state.logout ? 'is-submitting' : ''
              }`}
            >
              {logoutText.value}
              {/* is submitting spinner  */}
              <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
              </div>
            </a>
          </div>
        </div>
      );
    }

    /* render a spinner whilst the background logic takes place */

    return (
      <div className="section spinner" style={{ flexGrow: 1 }}>
        <div className="spinner--loading"></div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
