import React from 'react';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import ArticlesListing from '../ArticlesListing';

const ArticlesSection = (props) => {
  const { fields, params } = props;

  return (
    <div className={params.wrapperClass}>
      <div className="columns is-gapless">
        <div
          className="column column--bgImage is-one-third-tablet is-half-desktop is-three-quarters-widescreen"
          style={{
            backgroundImage: `url(${fields.image.value.src})`,
          }}
        >
          <figure className="image is-hidden-tablet">
            <Image media={fields.imageMobile} />
          </figure>
        </div>
        <div className="column is-two-thirds-tablet is-three-quarters-desktop">
          <div className="column__padding">
            <RichText
              tag="h2"
              className="title is-size-4-mobile"
              field={fields.heading}
            />

            {fields.articles.map((x, index) => {
              x.params = {
                isHiddenMobile: false,
                isHero: false,
              };
              if (index >= 3) {
                x.params.isHiddenMobile = true;
              }
              x.key = index;

              return <ArticlesListing {...x} />;
            })}
          </div>
          <div className="column--footer">
            <Link to={`${fields.internalLink.value.href}`}>
              {fields.internalLink.value.text}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesSection;
