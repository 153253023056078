import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import addRegToDevice from './../../../utilities/addRegToDevice';

const DeviceLink = (props) => {
  const { link, device, trademarkRequired } = props;
  const deviceName = addRegToDevice(device, trademarkRequired);

  return (
    <Link
      data-ecategory="Article Group"
      to={`${link}`}
      className="article__device__link"
      dangerouslySetInnerHTML={{ __html: deviceName }}
    />
  );
};

// Specifies the default values for props:
DeviceLink.defaultProps = {
  link: '',
  device: '',
  trademarkRequired: false,
};

DeviceLink.propTypes = {
  link: PropTypes.string,
  device: PropTypes.string,
  trademarkRequired: PropTypes.bool,
};

export default DeviceLink;
