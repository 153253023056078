import React from 'react';
import PropTypes from 'prop-types';
import AvailabilityStatus from './../AvailabilityStatus/AvailabilityStatus';
import config from './../../../common/config';

const ErrorScreen = (props) => {
  // chat button trigger
  function handleClick(e) {
    e.preventDefault();
    // chatbot
    const findChatBtn = document.querySelectorAll(
      '.embeddedServiceHelpButton button.helpButtonEnabled',
    );

    if (window.embedded_svc && findChatBtn.length > 0)
      window.embedded_svc.liveAgentAPI.startChat();

    // miaw
    const findMiawButton = document.querySelectorAll(
      '.embedded-messaging',
    );
    if (window.embeddedservice_bootstrap && findMiawButton.length > 0)
      window.embeddedservice_bootstrap.utilAPI.launchChat();
  }

  function handleDisabled(e) {
    e.preventDefault();
  }

  const statusLabelOnlineSupport =
    props.onlineSupport === 'true' ? 'Available' : 'Offline';
  const statusLabelChat =
    props.chat === 'true' ? 'Online' : 'Offline';

  return (
    <div className="error__screen">
      <div className="error__screen__intro">
        <figure className="error__screen__intro__image">
          <img src={props.image} alt="error" />
        </figure>
        <h2
          className="title is-3 is-spaced"
          dangerouslySetInnerHTML={{ __html: props.text.title }}
        />
        {props.errorStatus && (
          <>
            <p>
              <strong>Error code: {props.errorStatus}</strong>
            </p>
            <p>
              <br />
            </p>
          </>
        )}
        <p dangerouslySetInnerHTML={{ __html: props.text.text }} />
      </div>

      <div
        className={`content show-availability ${
          props.showList ? '' : 'is-hidden'
        }`}
      >
        {/* showBottomBorder: useful when you want to add something below the list like a back button */}
        <ul
          style={
            props.showBottomBorder
              ? {
                  borderBottomColor: '#efeeed',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                }
              : {}
          }
        >
          <li>
            <a
              href={`tel:+${config.countryDialingCode}-${config.phoneNumber}`}
              className={`include-icon-before icon-call ${
                props.onlineSupport === 'true'
                  ? 'is-online'
                  : 'is-offline'
              }`}
              disabled={props.onlineSupport === 'false'}
              onClick={
                props.onlineSupport === 'false'
                  ? handleDisabled
                  : undefined
              }
            >
              Call customer support on{' '}
              <span className="phone">{config.phoneNumber}</span>
              <AvailabilityStatus
                label={statusLabelOnlineSupport || ''}
                showLabel={true}
                class={
                  props.onlineSupport === 'true'
                    ? 'is-online'
                    : 'is-offline'
                }
              />
            </a>
          </li>

          <li>
            <a
              href="/"
              className={`include-icon-before include-icon-after icon-chat ${
                props.chat === 'true' ? 'is-online' : 'is-offline'
              }`}
              disabled={props.chat === 'false'}
              onClick={
                props.chat === 'false' ? handleDisabled : handleClick
              }
            >
              Chat online with a customer support member
              <AvailabilityStatus
                label={statusLabelChat || ''}
                showLabel={true}
                class={
                  props.chat === 'true' ? 'is-online' : 'is-offline'
                }
              />
            </a>
          </li>

          <li>
            <a
              href="https://www.cochlear.com/us/en/connect/contact-us/contact-us-recipient"
              target="_blank"
              rel="noopener noreferrer"
              className="include-icon-before icon-enquiry include-icon-after icon-external"
            >
              Make an online inquiry
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

// Specifies the default values for props:
ErrorScreen.defaultProps = {
  chat: 'false',
  errorStatus: '',
  image: '',
  onlineSupport: 'false',
  text: {},
  showBottomBorder: false,
  showList: true,
};

ErrorScreen.propTypes = {
  chat: PropTypes.string,
  errorStatus: PropTypes.string,
  image: PropTypes.string,
  onlineSupport: PropTypes.string,
  text: PropTypes.object,
  showBottomBorder: PropTypes.bool,
  showList: PropTypes.bool,
};

export default ErrorScreen;
