import React from 'react';
import { NavLink, Link, Redirect } from 'react-router-dom';
// import { withNamespaces } from 'react-i18next';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import ProfileDropdown from './components/custom/ProfileDropdown/ProfileDropdown';
import AvailabilityStatus from './components/custom/AvailabilityStatus/AvailabilityStatus';
import config from './common/config';
import { authenticationService, environment } from './_services';
import isRecipient from './utilities/isRecipient';
import getMinutes from './utilities/getMinutes';

const authAPI = config.authenticate[environment].authorization;
const domainValue = config.cookieDomain[environment];

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not, simply to support disconnected mode.
// let Navigation = (props) => {
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveSubNav: false,
      selectedFamily: null,
    };
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSearchToggle = this.handleSearchToggle.bind(this);
    this.handleCloseLink = this.handleCloseLink.bind(this);
    this.handleShowSub = this.handleShowSub.bind(this);
    this.resetActiveSubNav = this.resetActiveSubNav.bind(this);
    this.callbackWhatsnew = this.callbackWhatsnew.bind(this);
    this.callbackWhatsnewContent =
      this.callbackWhatsnewContent.bind(this);
    this.callbackShowPopOver = this.callbackShowPopOver.bind(this);
  }

  callbackWhatsnew() {
    this.props.callbackWhatsnew();
  }

  callbackWhatsnewContent(data) {
    this.props.callbackWhatsnewContent(data);
  }

  handleSearchToggle(e) {
    this.props.callbackToggleSeach(e);
  }

  callbackShowPopOver(bool) {
    this.props.callbackShowPopOver(bool);
  }

  handleShowSub(e) {
    // toggle active sub nav
    const { isActiveSubNav, selectedFamily } = this.state;

    const currentState = isActiveSubNav;

    // reset selected family when hidden
    const thisFamily = e && e.currentTarget && e.currentTarget.value;
    // const updateFamily =
    //   selectedFamily === thisFamily ? null : thisFamily;

    // for when switching families with an active family nav already
    if (
      thisFamily &&
      isActiveSubNav &&
      thisFamily !== selectedFamily &&
      selectedFamily !== null
    ) {
      return this.setState(
        { isActiveSubNav: false, selectedFamily: selectedFamily },
        () =>
          setTimeout(() => {
            this.setState({
              isActiveSubNav: true,
              selectedFamily: thisFamily,
            });
          }, 300),
      );
    }

    this.setState({
      isActiveSubNav: !currentState,
      selectedFamily: thisFamily,
    });
  }

  resetActiveSubNav() {
    // called when closing the nav
    // only reset if it is true
    const { isActiveSubNav } = this.state;
    const currentState = isActiveSubNav;
    if (currentState) {
      this.setState({
        isActiveSubNav: !currentState,
        selectedFamily: null,
      });
    }
  }

  handleNavigation(e) {
    if (e.target.parentElement.classList.contains('is-active')) {
      // if its already active, remove it
      e.target.parentElement.classList.remove('is-active');
      e.preventDefault();
    } else if (
      document.querySelector(
        '#navbarBasic .has-dropdown.is-active',
      ) !== null
    ) {
      // if something else is active, remove that and add it to the target
      document
        .querySelector('#navbarBasic .has-dropdown.is-active')
        .classList.remove('is-active');

      if (e.target.parentElement.classList.contains('has-dropdown')) {
        e.target.parentElement.classList.add('is-active');
        e.preventDefault();
      }
    } else {
      if (e.target.parentElement.classList.contains('has-dropdown')) {
        e.target.parentElement.classList.add('is-active');
        e.preventDefault();
      }
    }
  }

  handleCloseClick(e) {
    e.preventDefault();
    this.resetActiveSubNav();
    if (
      document.querySelector(
        '#navbarBasic .has-dropdown.is-active',
      ) !== null
    ) {
      document
        .querySelector('#navbarBasic .has-dropdown.is-active')
        .classList.remove('is-active');
    }
  }

  handleCloseLink() {
    this.resetActiveSubNav();
    if (
      document.querySelector(
        '#navbarBasic .has-dropdown.is-active',
      ) !== null
    ) {
      document
        .querySelector('#navbarBasic .has-dropdown.is-active')
        .classList.remove('is-active');
    }
  }

  render() {
    const {
      chat,
      onlineSupport,
      serviceRequests,
      currentUser,
      headerLogin,
      privatePath,
      isProfileEnabled,
      callbackWhatsnew,
      callbackWhatsnewContent,
      callbackShowPopOver,
      showPopOver,
      route,
      showSearch,
      features,
    } = this.props;

    const { isActiveSubNav, selectedFamily } = this.state;
    const { country, lang } = config;
    // text label strings
    const statusLabelOnlineSupport =
      onlineSupport === 'true' ? 'Available' : 'Offline';
    const statusLabelChat = chat === 'true' ? 'Online' : 'Offline';
    const statusLabelServiceRequests =
      serviceRequests === 'true' ? '' : 'Coming soon';

    function handleDisabled(e) {
      e.preventDefault();
    }

    function handleChatClick(e) {
      e.preventDefault();
      // chatbot
      const findChatBtn = document.querySelectorAll(
        '.embeddedServiceHelpButton button.helpButtonEnabled',
      );

      if (window.embedded_svc && findChatBtn.length > 0)
        window.embedded_svc.liveAgentAPI.startChat();

      // miaw
      const findMiawButton = document.querySelectorAll(
        '.embedded-messaging',
      );
      if (
        window.embeddedservice_bootstrap &&
        findMiawButton.length > 0
      )
        window.embeddedservice_bootstrap.utilAPI.launchChat();
    }

    function showModal(e) {
      e.preventDefault();
      let modal = document.getElementsByClassName(
        'modal--cant-see-my-device',
      )[0];
      modal.classList.add('is-active');
      let html = document.documentElement;
      html.classList.add('is-clipped');
    }

    const decoded = currentUser && jwtDecode(currentUser);
    const appAccess =
      decoded && decoded['https://www.cochlear.com/app'];
    const loggedIn = appAccess;

    // update the header login path based on environemnt
    if (environment !== 'PRD') {
      if (isProfileEnabled) {
        headerLogin.href = `${authAPI}/authorize?app=ds`;
      }
    }

    function handleServiceRequestLink(e) {
      // check if logged in
      const token = authenticationService.currentUserValue;
      const decoded = token && jwtDecode(token);
      const currentUserAttributes =
        authenticationService.currentUserAttributes;
      const personas =
        currentUserAttributes &&
        JSON.parse(currentUserAttributes).personas;

      const appAccess =
        decoded && decoded['https://www.cochlear.com/app'];
      const countryCode =
        decoded && decoded['https://www.cochlear.com/country_code'];
      const userType =
        decoded && decoded['https://www.cochlear.com/user_type'];
      const locale = decoded && decoded['locale'];
      // logged in user - check token exists and user has access
      // checking persona cookie too. checking for a patient/carer scenario
      if (
        token &&
        appAccess &&
        // checking persona cookie too for carers that have patient/carer scenario
        // because their usertype is carer, they dont have ds app claim
        // make sure user is us and does not allow pr
        countryCode.toLowerCase() === 'us' &&
        (appAccess.includes('ds') ||
          (userType.toLowerCase() === 'carer' &&
            isRecipient(personas)))
      ) {
        return true;
      }

      // logged in user - NO DS CLAIM
      if (token && appAccess) {
        authenticationService.setReferrer('location', 'goBackUrl');
        return (
          <Redirect
            to={{
              pathname: `/us/en/support/not-authorized`,
              state: {
                countryCode: countryCode,
                userType: userType,
                locale: locale,
              },
            }}
          />
        );
      }

      // not logged in
      // apply cookie for referrer as 'service-request' path
      // apply cookie for back/return button as current page url
      authenticationService.setReferrer('sr');
      authenticationService.setReferrer('location', 'goBackUrl');
      return true;
    }

    //console.log('route:', route);

    return (
      <div className="container">
        <nav
          className="navbar navbar--support"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a
              href={`https://www.cochlear.com/home`}
              className="image wordmark is-hidden-desktop include-icon-before icon-link"
            >
              <img
                src="https://assets.cochlear.com/api/public/content/89a2ac1ae1e046069e3b37a3bf54431b?v=015cdf30"
                alt="Cochlear"
                className="black"
              />
              <img
                src="https://assets.cochlear.com/api/public/content/26d45fc195214b6a921e42d50b137acf?v=2523ef34"
                alt="Cochlear"
                className="reversed"
              />
            </a>
            <div className="navbar-end is-hidden-desktop">
              {loggedIn ? (
                <ProfileDropdown
                  isActiveProfile={this.props.isActiveProfile}
                  onChildClick={this.props.onChildClick}
                  privatePath={privatePath}
                  callbackWhatsnew={callbackWhatsnew}
                  callbackWhatsnewContent={callbackWhatsnewContent}
                  callbackShowPopOver={callbackShowPopOver}
                  showPopOver={showPopOver}
                  features={features}
                />
              ) : (
                // confirm header-footer has a login link
                headerLogin &&
                headerLogin.href && (
                  <a
                    href={headerLogin.href}
                    className="login-btn"
                    onClick={() => {
                      // Set referrerUrl as a cookie as it needs to be shared across sub domains
                      return Cookies.set(
                        'referrerUrl',
                        window.location.href,
                        {
                          path: '/',
                          domain: domainValue,
                          secure: true,
                          expires: getMinutes(15),
                        },
                      );
                    }}
                  >
                    {headerLogin.title || 'Login'}
                  </a>
                )
              )}
            </div>
            <div className="image brandmark is-hidden-touch">
              <img
                src="https://assets.cochlear.com/api/public/content/ce738fff4a224ee6b766827d2cfbfcec?v=37116a35"
                alt="Cochlear"
                className="full-colour"
              />
              <img
                src="https://assets.cochlear.com/api/public/content/4c16ff4216ef4491b98e99b03609b3ff?v=54731b96"
                alt="Cochlear"
                className="reversed"
              />
            </div>
          </div>

          <div id="navbarBasic" className="navbar-menu is-active">
            {' '}
            <div className="navbar-start">
              <NavLink
                exact
                to={`/${country}/${lang}/support/home`}
                className="navbar-item mobile__icon mobile__icon--home site-title"
                activeClassName="is-current-page"
                onClick={this.handleNavigation}
              >
                <span className="is-hidden-touch">Device&nbsp;</span>
                Support
              </NavLink>

              <div className="select-device navbar-item has-dropdown">
                <a
                  href="/"
                  className="navbar-link mobile__icon mobile__icon--device"
                  onClick={this.handleNavigation}
                >
                  {' '}
                  {route.fields.navigation.fields.selectDevice.value}
                </a>
                <div className="navbar-dropdown">
                  <div className="container navbar-container ">
                    <div className="navbar-items">
                      <div className="navbar-item navbar-item--menu-title">
                        <span className="is-hidden-desktop">
                          Menu
                        </span>
                        <span className="is-hidden-touch">
                          Select processor system
                        </span>
                        <button
                          className="is-larger delete"
                          onClick={this.handleCloseClick}
                        ></button>
                      </div>

                      {/* nucleus */}
                      <button
                        type="button"
                        value="nucleus"
                        className={`navbar-item nucleus-8 include-icon-before include-icon-after icon-link ${
                          isActiveSubNav &&
                          selectedFamily === 'nucleus'
                            ? 'is-active'
                            : ''
                        }`}
                        onClick={this.handleShowSub}
                      >
                        <div>
                          {
                            route.fields.navigation.fields
                              .nucleusSoundProcessors.value
                          }
                        </div>
                      </button>

                      {/* kanso */}
                      <button
                        type="button"
                        value="kanso"
                        className={`navbar-item kanso include-icon-before include-icon-after icon-link ${
                          isActiveSubNav && selectedFamily === 'kanso'
                            ? 'is-active'
                            : ''
                        }`}
                        onClick={this.handleShowSub}
                      >
                        <div>
                          {
                            route.fields.navigation.fields
                              .kansoSoundProcessors.value
                          }
                        </div>
                      </button>

                      {/* baha */}
                      <button
                        type="button"
                        value="baha"
                        className={`navbar-item baha include-icon-before include-icon-after icon-link ${
                          isActiveSubNav && selectedFamily === 'baha'
                            ? 'is-active'
                            : ''
                        }`}
                        onClick={this.handleShowSub}
                      >
                        <div>
                          {
                            route.fields.navigation.fields
                              .bahaSoundProcessors.value
                          }
                        </div>
                      </button>

                      {/* osia */}
                      {route.fields.navigation.fields.Osia.map(
                        (x, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              value=""
                              className={`navbar-item osia-2 include-icon-before include-icon-after icon-link ${
                                isActiveSubNav &&
                                selectedFamily === 'osia-2'
                                  ? 'is-active'
                                  : ''
                              }`}
                            >
                              <Link
                                to={x.fields.link.value.href}
                                className=""
                                onClick={this.handleCloseLink}
                              >
                                <div>{x.fields.link.value.text}</div>
                              </Link>
                            </button>
                          );
                        },
                      )}

                      <a
                        className="navbar-item i-cant-see-my-device include-icon-before include-icon-after icon-link"
                        href="/"
                        onClick={showModal}
                      >
                        {
                          route.fields.navigation.fields
                            .processorNotHere.value
                        }
                      </a>
                    </div>
                    <div
                      className={`navbar-items navbar-items--secondary ${
                        isActiveSubNav ? 'is-active' : ''
                      }`}
                    >
                      <div className="navbar-item navbar-item--menu-title">
                        <button
                          type="button"
                          className="is-hidden-desktop btn-back include-icon-before icon-link"
                          onClick={this.handleShowSub}
                        >
                          Back
                        </button>
                        <span className="is-hidden-touch">
                          Select model
                        </span>
                        <button
                          className="is-hidden-desktop is-larger delete"
                          onClick={this.handleCloseClick}
                        ></button>
                      </div>

                      {selectedFamily === 'nucleus' && (
                        <React.Fragment>
                          <div className="navbar-item navbar-item--menu-sub-title is-hidden-desktop">
                            {
                              route.fields.navigation.fields
                                .nucleusSoundProcessors.value
                            }
                          </div>

                          {route.fields.navigation.fields.Nucleus.map(
                            (x, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={x.fields.link.value.href}
                                  className="navbar-item include-icon-after icon-link"
                                  onClick={this.handleCloseLink}
                                >
                                  <div>
                                    {x.fields.link.value.text}
                                  </div>
                                </Link>
                              );
                            },
                          )}
                        </React.Fragment>
                      )}

                      {selectedFamily === 'kanso' && (
                        <React.Fragment>
                          <div className="navbar-item navbar-item--menu-sub-title is-hidden-desktop">
                            {
                              route.fields.navigation.fields
                                .kansoSoundProcessors.value
                            }
                          </div>

                          {route.fields.navigation.fields.Kanso.map(
                            (x, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={x.fields.link.value.href}
                                  className="navbar-item include-icon-after icon-link"
                                  onClick={this.handleCloseLink}
                                >
                                  <div>
                                    {x.fields.link.value.text}
                                  </div>
                                </Link>
                              );
                            },
                          )}
                        </React.Fragment>
                      )}

                      {selectedFamily === 'baha' && (
                        <React.Fragment>
                          <div className="navbar-item navbar-item--menu-sub-title is-hidden-desktop">
                            {
                              route.fields.navigation.fields
                                .bahaSoundProcessors.value
                            }
                          </div>

                          {route.fields.navigation.fields.Baha.map(
                            (x, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={x.fields.link.value.href}
                                  className="navbar-item include-icon-after icon-link"
                                  onClick={this.handleCloseLink}
                                >
                                  <div>
                                    {x.fields.link.value.text}
                                  </div>
                                </Link>
                              );
                            },
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="talk-to-support navbar-item has-dropdown">
                <a
                  href="/"
                  className="navbar-link mobile__icon mobile__icon--chat"
                  onClick={this.handleNavigation}
                >
                  {route.fields.navigation.fields.talkToSupport.value}
                </a>
                <div className="navbar-dropdown">
                  <div className="container navbar-container ">
                    <div className="navbar-items show-availability">
                      <div className="navbar-item navbar-item--menu-title">
                        <span className="is-hidden-desktop">
                          Menu
                        </span>
                        <span className="is-hidden-touch">
                          We're here to help
                        </span>
                        <button
                          className="is-hidden-desktop is-larger delete"
                          onClick={this.handleCloseClick}
                        ></button>
                      </div>

                      <a
                        data-ecategory="Nav talk to support"
                        href={`tel:+${config.countryDialingCode}-${config.phoneNumber}`}
                        className={`navbar-item include-icon-before icon-call ${
                          onlineSupport === 'true'
                            ? 'is-online'
                            : 'is-offline'
                        }`}
                        disabled={onlineSupport === 'false'}
                        onClick={
                          onlineSupport === 'false'
                            ? handleDisabled
                            : undefined
                        }
                      >
                        Call{' '}
                        <span className="is-hidden-desktop">
                          Customer support on{' '}
                        </span>
                        <span className="phone">
                          {config.phoneNumber}
                        </span>
                        <AvailabilityStatus
                          label={statusLabelOnlineSupport || ''}
                          showLabel={true}
                          class={
                            onlineSupport === 'true'
                              ? 'is-online'
                              : 'is-offline'
                          }
                        />
                      </a>
                      <a
                        data-ecategory="Nav talk to support"
                        href="/"
                        className={`navbar-item include-icon-before include-icon-after icon-chat ${
                          chat === 'true' ? 'is-online' : 'is-offline'
                        }`}
                        disabled={chat === 'false'}
                        onClick={
                          chat === 'false'
                            ? handleDisabled
                            : handleChatClick
                        }
                      >
                        {route.fields.navigation.fields.chat.value}
                        <AvailabilityStatus
                          label={statusLabelChat || ''}
                          showLabel={true}
                          class={
                            chat === 'true'
                              ? 'is-online'
                              : 'is-offline'
                          }
                        />
                      </a>
                      <Link
                        data-ecategory="Nav talk to support"
                        to={`/${country}/${lang}/support/service-request`}
                        className={`navbar-item  include-icon-before include-icon-after icon-service icon-link ${
                          serviceRequests === 'true'
                            ? ''
                            : 'is-coming-soon'
                        }`}
                        disabled={serviceRequests === 'false'}
                        onClick={
                          serviceRequests === 'false'
                            ? handleDisabled
                            : handleServiceRequestLink
                        }
                      >
                        {
                          route.fields.navigation.fields.submitRepair
                            .value
                        }
                        {serviceRequests === 'false' && (
                          <div className="availability-status availability-status--is-coming-soon">
                            {statusLabelServiceRequests}
                          </div>
                        )}
                      </Link>
                      <a
                        data-ecategory="Nav talk to support"
                        href={
                          route.fields.navigation.fields.contactUsLink
                            .value
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="navbar-item include-icon-before icon-enquiry include-icon-after icon-external"
                      >
                        {
                          route.fields.navigation.fields.onlineEnquiry
                            .value
                        }
                      </a>

                      <a
                        data-ecategory="Nav talk to support"
                        href={
                          route.fields.navigation.fields
                            .deviceSupportLink.value
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="navbar-item include-icon-before icon-user-guides include-icon-after icon-external"
                      >
                        {
                          route.fields.navigation.fields.visitSupport
                            .value
                        }
                      </a>
                    </div>
                    <div className="navbar-items navbar-items-support">
                      <div className="support__content is-hidden-touch">
                        <h2 className="title is-4 include-icon-before">
                          {
                            route.fields.navigation.fields.afterhours
                              .value
                          }
                        </h2>
                        <a
                          href={
                            route.fields.navigation.fields
                              .contactUsLink.value
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="sdw__button sdw__button--cochlear-yellow-purple-text is-external-link"
                        >
                          {
                            route.fields.navigation.fields
                              .onlineEnquiry.value
                          }
                        </a>
                      </div>
                      <button
                        className="is-hidden-touch is-larger delete"
                        onClick={this.handleCloseClick}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="navbar-item navbar-end">
                <button
                  type="button"
                  className={`is-hidden-touch include-icon-after icon ${
                    showSearch ? 'icon--close' : 'icon--search'
                  }`}
                  onClick={this.handleSearchToggle}
                ></button>

                <a
                  href="/"
                  className="is-hidden-desktop navbar-link mobile__icon mobile__icon--search"
                  onClick={this.handleSearchToggle}
                >
                  {' '}
                  Search
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withNamespaces()(Navigation);
export default Navigation;
