import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import ArticleDate from './../custom/ArticleDate/ArticleDate';

import CategoryLink from './../custom/CategoryLink/CategoryLink';
import DeviceLink from './../custom/DeviceLink/DeviceLink';

import upgradeIcon from './../../assets/icons/upgrade_hero.png';

const ArticlesListing = (props) => {
  const { fields } = props;

  // strip out spaces, replace with hypens and transform to lowercase

  if (fields.isHero.value) {
    return (
      <div className="articles__list__item articles__list__item--hero">
        <figure className="articles__list__item__image">
          <img src={upgradeIcon} alt="upgrade" className="" />
        </figure>
        <div className="articles__list__item__content">
          <div className="articles__list__item__title">
            <RichText
              tag="h3"
              className="title"
              field={fields.title}
            />
          </div>
          <div className="articles__list__item__details">
            <RichText tag="p" field={fields.content} />
            <Link
              data-ecategory="Articles Listing"
              to={`${fields?.heading?.value?.href}`}
              className="sdw__button sdw__button--link"
            >
              {fields.heading.value.text}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`articles__list__item ${
        fields?.isHiddenMobile?.value ? 'is-hidden-mobile' : ''
      }`}
    >
      <div className="articles__list__item__title">
        <h3 className="title">
          <Link
            data-ecategory="Articles Listing"
            to={`${fields.heading.value.href}`}
            dangerouslySetInnerHTML={{
              __html: fields.heading.value.text,
            }}
          />
        </h3>
      </div>
      <div className="articles__list__item__details">
        <ArticleDate
          label={fields.dateLabel.value}
          date={fields.date.value}
        />
        <span className="articles__list__item__details__group">
          <DeviceLink
            link={fields.devicePage.value.href}
            device={fields.devicePage.value.text}
          />

          <CategoryLink
            link={fields.categoryPage.value.href}
            category={fields.categoryPage.value.text}
            class={fields.categoryPage.value.class}
          />
        </span>
      </div>
    </div>
  );
};

export default ArticlesListing;
