import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { Link, useLocation } from 'react-router-dom';

const Category = (props) => {
  const { fields } = props;
  const location = useLocation();

  let path = `${fields?.internalLink?.value}`;

  let fullPath = path;

  if (fields.includeCurrent?.value) {
    const { pathname } = location;
    fullPath = `${pathname}/${path}`;
  }

  return (
    <Link
      data-ecategory="Problem Category"
      data-elabel={fields?.title?.value}
      to={`${fullPath}`}
    >
      <div className="media hover-grow">
        <div className="media-left">
          <figure className="image is-square">
            <Image media={fields.image} />
          </figure>
        </div>
        <div className="media-content">
          <Text tag="p" field={fields.title} />
          <Text
            tag="span"
            className="is-hidden-touch"
            field={fields.subtitle}
          />
        </div>
      </div>
    </Link>
  );
};

Category.propTypes = {
  fields: PropTypes.shape({
    /** Description of prop "image". */
    image: PropTypes.shape({
      value: PropTypes.object,
    }),
    internalLink: PropTypes.shape({
      value: PropTypes.string,
    }),
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    // forDevice is a dynamically added param used to identify device category
    forDevice: PropTypes.string,
  }),
};

export default Category;
