import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import config from './../../../../common/config';

const Confirmation = (props) => {
  const DisplayDate = (props) => {
    const date = new Date(props.date);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return (
      <span className="article__date">
        {('0' + (month + 1)).slice(-2)} / {day} / {year}
      </span>
    );
  };

  const {
    serialNumber,
    device,
    firstName,
    lastName,
    countryDialingCode,
    mobilePhone,
    email,
    clinicName,
    clinicianName,
    problemDescription,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    zipCode,
    comfirmationScreenText,
  } = props;

  return (
    <div className="confirmation">
      <div className="confirmation__intro">
        <figure className="confirmation__intro__image">
          <img
            src="https://assets.cochlear.com/api/public/content/917e4a953c5643188e95e0f60623a05f?v=fec7e940"
            alt="Confirmation"
          />
        </figure>
        <h2 className="title is-3 is-spaced">
          <Text
            field={
              comfirmationScreenText.confirmationTitleBeforeFirstName
            }
          />
          &nbsp;{firstName}!&nbsp;
          <Text
            field={
              comfirmationScreenText.confirmationTitleAfterFirstName
            }
          />
        </h2>
        <Text
          tag="p"
          field={comfirmationScreenText.confirmationLead}
        />
      </div>
      <hr />
      <div className="columns is-multiline">
        <div className="column is-4">
          <Text
            tag="h3"
            className="title"
            field={comfirmationScreenText.confirmationSectionTitle1}
          />
          <p>{device.customerFacingName}</p>
          <p>
            <Text
              field={comfirmationScreenText.confirmationSectionLabel1}
            />
            <DisplayDate date={device.latestWarrantyDate} />
          </p>
          <p>
            <Text
              field={comfirmationScreenText.confirmationSectionLabel2}
            />
            {serialNumber}
          </p>
        </div>
        <div className="column is-4">
          <Text
            tag="h3"
            className="title"
            field={comfirmationScreenText.confirmationSectionTitle2}
          />
          <p>
            {firstName} {lastName}
          </p>
          <p>
            <Text
              field={comfirmationScreenText.confirmationSectionLabel3}
            />
            {countryDialingCode} {mobilePhone}
          </p>
          <p>
            <Text
              tag="span"
              className="is-hidden-mobile"
              field={comfirmationScreenText.confirmationSectionLabel4}
            />
            {email}
          </p>
        </div>
        <div className="column is-4">
          <Text
            tag="h3"
            className="title"
            field={comfirmationScreenText.confirmationSectionTitle3}
          />
          <p>{addressLine1}</p>
          {addressLine2 && addressLine3 ? (
            <p>
              {addressLine2}, {addressLine3}
            </p>
          ) : (
            <p>{addressLine2}</p>
          )}
          {!addressLine2 && addressLine3 && <p>{addressLine3}</p>}
          <p>
            {city}, {state}, {zipCode}
          </p>
          {config.countryName}
        </div>
        <div className="column is-12">
          <Text
            tag="h3"
            className="title"
            field={comfirmationScreenText.confirmationSectionTitle4}
          />
          <p>{clinicName}</p>
          <p>{clinicianName}</p>
        </div>
        <div className="column is-12">
          <Text
            tag="h3"
            className="title"
            field={comfirmationScreenText.confirmationSectionTitle5}
          />
          <p className="confirmation__description">
            {problemDescription}
          </p>
        </div>
      </div>
      <Link
        to={comfirmationScreenText?.confirmationBtnLink?.value}
        className="sdw__button sdw__button--cochlear-yellow sdw__button-back"
      >
        <Text field={comfirmationScreenText.confirmationBtnLabel} />
      </Link>
    </div>
  );
};

export default Confirmation;
