import React from 'react';
import PropTypes from 'prop-types';
import ArticlesListing from '../ArticlesListing';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const ArticlesAllSection = (props) => {
  const { params, fields } = props;

  return (
    <div className={params.wrapperClass}>
      <div className="columns">
        <div className="column is-full">
          {fields.articles.map((x) => {
            x.params = {
              isHero: false,
            };
            return <ArticlesListing key={x.id} {...x} />;
          })}
        </div>
      </div>
    </div>
  );
};

ArticlesAllSection.propTypes = {
  fields: PropTypes.shape({
    wrapperClass: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

export default ArticlesAllSection;
