import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import config from '../common/config';
import { environment } from './environment';
import getMinutes from '../utilities/getMinutes';

const {
  country,
  lang,
  authenticate,
  cookieDomain,
  baseURL,
  auth0: auth0Config,
} = config;
const authAPI = authenticate[environment].authorization;
const domainValue = cookieDomain[environment];
const auth0 = auth0Config[environment];

function logout(isPrivatePath) {
  const token = authenticationService.currentUserValue;
  const auth0LogoutUrl = `${auth0.customDomain}/v2/logout?client_id=${auth0.clientId}&returnTo=${auth0.logoutRedirectUrl}`;

  // Set the referrerUrl
  const currentPage = isPrivatePath
    ? baseURL[environment] + country + '/' + lang + '/support/home'
    : window.location.href; // remove leading slash from this path

  Cookies.set('referrerUrl', currentPage, {
    path: '/',
    domain: domainValue,
    secure: true,
    expires: getMinutes(15),
  });
  if (token) {
    axios
      .post(`${authAPI}/revoke`, { token })
      .then((response) => {
        if (window && window.embeddedservice_bootstrap)
          window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
      })
      .catch((error) => {
        // TODO Ideally this error should be handled
      })
      .then(() => {
        // remove user from local storage to log user out
        clearLoggedInUser();
        // Redirect to auth0 logout
        window.location.replace(auth0LogoutUrl);
      });
  } else {
    // The user doesn't have a token, so just redirect to auth0's logout
    window.location.replace(auth0LogoutUrl);
  }
}

function hasAppClaim(token) {
  const decoded = token && jwtDecode(token);
  const appAccess =
    decoded && decoded['https://www.cochlear.com/app'];
  return appAccess;
}

// can set a referrer for current page and sr path
// by default will set current page as referrer
function setReferrer(type = 'location', name = 'referrerUrl') {
  // Not logged in so redirect to login page
  // set this location as the referrer url
  const referrerUrl =
    typeof window !== 'undefined'
      ? type !== 'sr'
        ? window.location.href
        : `${baseURL[environment]}/service-request`
      : `${baseURL[environment]}/home`;

  Cookies.set(name, referrerUrl, {
    path: '/',
    domain: domainValue,
    secure: true,
    expires: getMinutes(15),
  });
}

// renew the users token
function renew() {
  // clear app session
  clearLoggedInUser();
  // Not logged in so redirect to login page
  // set this location as the referrer url
  setReferrer();

  // redirect them to the authorizer url
  window.location.replace(`${authAPI}/authorize?app=ds`);
  return null;
}
function clearLoggedInUser() {
  Cookies.remove('currentUser', {
    domain: domainValue,
  });
  Cookies.remove('accessToken', {
    domain: domainValue,
  });
}

function login(code) {
  return axios
    .post(`${authAPI}/token`, { code })
    .then((response) => {
      if (response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout();
        }
        const error = response.message;
        return Promise.reject(error);
      }
      return response.data;
    })
    .then((user) => {
      Cookies.set('currentUser', user, {
        domain: domainValue,
        secure: true,
        expires: getMinutes(120),
      });

      return user;
    });
}

export const authenticationService = {
  login,
  logout,
  renew,
  setReferrer,
  hasAppClaim,
  updateCurrentUser(user) {
    Cookies.set('currentUser', user, {
      domain: domainValue,
      secure: true,
      expires: getMinutes(120),
    });
  },
  get currentUserValue() {
    return Cookies.get('currentUser');
  },
  get currentUserAttributes() {
    return Cookies.get('currentUserAttributes');
  },
};
