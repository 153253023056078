import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import addRegToDevice from './../../utilities/addRegToDevice';

function showModal(e) {
  e.preventDefault();
  let modal = document.getElementsByClassName(
    'modal--cant-see-my-device',
  )[0];
  modal.classList.add('is-active');
  let html = document.documentElement;
  html.classList.add('is-clipped');
}

const DropdownLink = (props) => {
  const iconClass = props.params.class
    ? props.params.class
    : props.fields.internalLink.value.text
        .replace(/ /g, '-')
        .replace(/'/g, '')
        .toLowerCase();
  const deviceName = addRegToDevice(
    props.fields.internalLink.value.text,
    true,
  );
  if (props.params.type === 'devices') {
    if (props.params.showModal === 'false') {
      return (
        <Link
          data-ecategory="Switch Device"
          className={`dropdown-item include-icon-before include-icon-after ${iconClass} ${
            props.params.isActive ? 'is-active' : ''
          }`}
          to={`${props.fields.internalLink.value.href}`}
        >
          <p dangerouslySetInnerHTML={{ __html: deviceName }} />
          <Text tag="span" field={props.fields.tagline} />
        </Link>
      );
    } else {
      return (
        <React.Fragment>
          <hr />
          <Link
            className={`dropdown-item include-icon-before include-icon-after ${iconClass}`}
            onClick={showModal}
            to={`${props.fields.internalLink.value.href}`}
          >
            {props.fields.internalLink.value.text}
          </Link>
        </React.Fragment>
      );
    }
  }
  return (
    <Link
      className={`dropdown-item include-icon-before include-icon-after ${iconClass} ${
        props.params.isActive ? 'is-active' : ''
      }`}
      to={`${props.fields.internalLink.value.href}`}
    >
      {props.fields.internalLink.value.text}
    </Link>
  );
};

export default DropdownLink;
