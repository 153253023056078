import React, { useEffect } from 'react';
import axios from 'axios';

import config from './../../../common/config';
import { environment } from './../../../_services';

const chatConfig = config.miawChat[environment];

const MIAWChat = ({ action, callbackMiawCheck }) => {
  const siteUrl = chatConfig.siteUrl;

  const activeAgentUpdate = (action) => {
    action(true);
  };

  // given an offset value, gets current time
  const calcTime = (offset) => {
    let d = new Date();
    let utc = d.getTime() + d.getTimezoneOffset() * 60000;
    let nd = new Date(utc + 3600000 * offset);
    return nd;
  };

  const getBusinessHours = async () => {
    try {
      let response = await axios.get(
        `${chatConfig.scrtUrl}/embeddedservice/v1/businesshours?orgId=${chatConfig.orgId}&esConfigName=${chatConfig.embeddedServiceAPIName}`,
      );
      response = await response.data.businessHoursInfo.businessHours;
      return response;
    } catch (error) {
      // Handle errors
      return error;
    }
  };

  const initEmbeddedMessaging = () => {
    try {
      window.embeddedservice_bootstrap.settings.language =
        chatConfig.language; // For example, enter 'en' or 'en-US'

      // Override default behavior and hide the chat button at initialization
      // window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;

      window.embeddedservice_bootstrap.init(
        chatConfig.orgId,
        chatConfig.embeddedServiceAPIName,
        siteUrl,
        {
          scrt2URL: chatConfig.scrtUrl,
        },
      );

      window.addEventListener('onEmbeddedMessagingReady', () => {
        setTimeout(() => {
          const findMiawButton = document.querySelectorAll(
            '.embedded-messaging',
          );

          if (findMiawButton.length > 0) activeAgentUpdate(action);
        }, '1000');
      });

      // not working on local using SIT script
      // leaving it in to test if it works on different env
      window.addEventListener(
        'onEmbeddedMessagingBusinessHoursStarted',
        () => {
          activeAgentUpdate(action);
        },
      );

      window.addEventListener(
        'onEmbeddedMessagingBusinessHoursEnded',
        () => {
          window.embeddedservice_bootstrap.removeMarkup();
          window.embeddedservice_bootstrap.removeEventHandlers();

          // 8am - 5pm check

          if (config.showChatbot) {
            (async () => {
              const businessHours = await getBusinessHours();
              const getLocalTime = calcTime(11).getTime();
              let startTime = businessHours[0].startTime;
              let beforeStart = new Date(startTime - 3600000 * 0.5);

              let endTime = businessHours[0].endTime;
              let afterEnd = new Date(endTime + 3600000 * 0.5);

              // is current time within 30 minutes prior to business opening time for current day
              if (
                startTime > getLocalTime &&
                getLocalTime > beforeStart
              )
                return false;

              // is current time within 30 minutes after business ending time for current day
              if (getLocalTime > endTime && getLocalTime < afterEnd)
                return false;

              return callbackMiawCheck();
            })();
          }
          //
        },
      );
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  };

  // setTimeout(() => {

  //   console.log("IFRAME scanning");
  //   window.location.reload();

  // }, 40000)

  useEffect(() => {
    if (!window.embeddedservice_bootstrap) {
      // Meta Tag for referrer
      const metaTag = document.createElement('meta');
      metaTag.name = 'referrer';
      metaTag.content = 'origin';
      document.head.appendChild(metaTag);

      // JS for onload
      const s = document.createElement('script');
      s.setAttribute('src', `${siteUrl}/assets/js/bootstrap.min.js`);
      s.onload = function onloadOverride() {
        initEmbeddedMessaging();
      };
      document.body.appendChild(s);
    } else {
      initEmbeddedMessaging();
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   LiveAgentUpdate(action);

  //   // returned function will be called on component unmount
  //   return () => {};
  //   // eslint-disable-next-line
  // }, []);

  return <div />;
};

export default MIAWChat;
