import React, { Component } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import config from './../../../common/config';
import dataWarrantyStatus from './dataWarrantyStatus';
import dataDeviceListLables from './dataDeviceListLables';

import {
  authenticationService,
  environment,
} from './../../../_services';
import addRegToDevice from './../../../utilities/addRegToDevice';
import getMinutes from './../../../utilities/getMinutes';
import help from './../../../assets/icons/help.svg';

const { authenticate, cookieDomain, baseURL } = config;
const patientsAPI = authenticate[environment].patients;
const domainValue = cookieDomain[environment];
const baseUrl = baseURL[environment];

class MyDeviceList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.deviceButtons = [];

    this.state = {
      devices: [],
      isLoading: false,
      error: null,
      serial: undefined,
      timeout: null,
    };
  }

  warrantyStatus(date) {
    // given a date, will return a string to show the user
    // 2+ years - 2 years remain
    // 23 months - 2 months remain
    // 59 days - 0 days remain

    const date1 = new Date(); // today
    date1.setHours(0, 0, 0); // set to zero hours in date

    const date2 = new Date(date); // warranty
    date2.setHours(0, 0, 0); // set to zero hours in date

    const oneDay = 1000 * 60 * 60 * 24; // number of seconds in a day

    // difference in days
    const diff =
      Math.round(
        Math.abs((date1.getTime() - date2.getTime()) / oneDay),
      ) + 1; // add 1 as it's inclusive, ie, the last day of warranty should display 1 not 0

    if (diff >= 365 * 2) {
      // 2 years
      const yearsResult = Math.floor(diff / 365);
      return `${yearsResult} ${dataWarrantyStatus.yearsRemain}`;
    }
    if (diff > 60) {
      // approx 2 months
      const monthsResult = Math.floor(diff / 30.416666666666667);
      return `${monthsResult} ${dataWarrantyStatus.monthsRemain}`;
    }
    if (diff === 60) {
      // approx 2 months
      return `2 ${dataWarrantyStatus.monthsRemain}`;
    }
    if (diff !== 1) {
      return `${diff} ${dataWarrantyStatus.daysRemain}`;
    }
    return `1 ${dataWarrantyStatus.dayRemains}`;
  }

  getImgSrc(name, color) {
    color = color || 'N/A'; // if it is an empty string, replace it with 'N/A'
    const nameCompact = name.replace(/ /g, '-').toLowerCase();
    const colorCompact = color.replace(/ /g, '-').toLowerCase();
    switch (nameCompact) {
      case 'baha-5':
      case 'baha-5-power':
      case 'baha-5-superpower':
      case 'baha-6-max':
      case 'kanso':
      case 'nucleus-8':
      case 'nucleus-7':
      case 'nucleus-6':
      case 'nucleus-5':
        // if color is N/A, we will display a default colour selection for that product
        return color !== 'N/A'
          ? `${nameCompact}--${colorCompact}`
          : `${nameCompact}--default`;
      case 'baha-4':
      case 'freedom':
      case 'kanso-2':
      case 'osia-2':
        return `${nameCompact}`;
      default:
        return 'generic';
    }
  }

  showModal(type) {
    // e.preventDefault();
    let modal = document.getElementsByClassName('modal--' + type)[0];
    modal.classList.add('is-active');
    let html = document.documentElement;
    html.classList.add('is-clipped');
  }

  handleClick(warranty, supported, serialNumber, device, hasOpenSR) {
    // check if device has open sr already
    if (hasOpenSR) {
      this.showModal('device-already-submitted');
      window.dataLayer.push({
        event: 'gtm-form',
        eventCategory: 'SR select device',
        eventAction: 'hasOpenSR',
        eventLabel: device.customerFacingName,
      });
      return false;
    }
    // check if sr supports this device
    if (!supported) {
      this.showModal('device-not-supported');
      window.dataLayer.push({
        event: 'gtm-form',
        eventCategory: 'SR select device',
        eventAction: 'notSupported',
        eventLabel: device.customerFacingName,
      });
      return false;
    }
    // check if it's in warranty
    if (!warranty) {
      this.showModal('device-out-of-warranty');
      window.dataLayer.push({
        event: 'gtm-form',
        eventCategory: 'SR select device',
        eventAction: 'outOfWarranty',
        eventLabel: device.customerFacingName,
      });
      return false;
    }
    window.dataLayer.push({
      event: 'gtm-form',
      eventCategory: 'SR select device',
      eventAction: 'supported',
      eventLabel: device.customerFacingName,
    });
    this.props.handleSelect(serialNumber, device); // adds serial to the form data and proceeds to next step
  }

  checkMatchingSerial() {
    const { timeout, serial } = this.state;
    if (this.deviceButtons.length > 0) {
      this.deviceButtons.map((deviceButton) => {
        const getDataSerial =
          deviceButton.getAttribute('data-serial');

        if (getDataSerial === serial) {
          // console.log('match found');
          deviceButton.click();
        }
        clearTimeout(timeout);
        return undefined;
      });
    } else {
      // console.log('empty');

      clearTimeout(timeout);

      this.setState({
        timeout: setTimeout(() => {
          this.checkMatchingSerial();
        }, 1000),
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });

    const token = authenticationService.currentUserValue;
    // Make a request for a user with a given ID
    const decoded = token && jwtDecode(token);
    const id = decoded['https://www.cochlear.com/cochlear_id'];
    axios
      .get(
        `${patientsAPI}/me/devices?id=${id}&filterField=deviceType&filterValue=Sound Processor`,
        {
          params: {},
          headers: { Authorization: 'Bearer ' + token },
        },
      )
      .then((result) => {
        if (this._isMounted) {
          // from returned response
          const newToken =
            result.headers['x-amzn-remapped-authorization'];

          if (newToken) {
            this.updateUserToken(newToken);
          }

          const queryParams = new URLSearchParams(
            window.location.search,
          );
          // look for device serial number in the url
          const serial = queryParams.get('serial') || undefined;
          this.setState(
            {
              devices: result.data,
              isLoading: false,
              serial,
            },
            () => {
              serial && this.checkMatchingSerial();
            },
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          const response = error.response;
          if ([401, 403].indexOf(response.status) !== -1) {
            authenticationService.renew();
            return false;
          }
          this.setState({
            error,
            isLoading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { timeout } = this.state;
    clearTimeout(timeout);
  }

  updateUserToken(token) {
    // update service
    authenticationService.updateCurrentUser(token);

    // update cookie
    Cookies.set('currentUser', token, {
      domain: domainValue,
      secure: true,
      expires: getMinutes(120),
    });
  }

  render() {
    const {
      selectedProcessor,
      profileIsLoading,
      helpBtnMobile,
      helpBtnDesktop,
    } = this.props;
    const { devices, isLoading, error } = this.state;

    return (
      <div>
        {error ? (
          <p>{error.message}</p>
        ) : isLoading || profileIsLoading ? (
          <div className="section spinner">
            <div className="spinner--loading"></div>
          </div>
        ) : (
          <React.Fragment>
            <div className="columns is-multiline device__list">
              {devices.length ? (
                devices.map((device, index) => {
                  // is it in warranty
                  const {
                    latestWarrantyDate,
                    serialNumber,
                    customerFacingName,
                    colour,
                    underWarranty,
                    isSupported,
                    hasOpenSR,
                  } = device;

                  const warrantyStatus =
                    underWarranty &&
                    this.warrantyStatus(latestWarrantyDate);

                  // user has clicked on this one
                  const isActive =
                    selectedProcessor === serialNumber ? true : false;
                  const imgSrc = this.getImgSrc(
                    customerFacingName,
                    colour,
                  );
                  const deviceName = addRegToDevice(
                    customerFacingName,
                    true,
                  );

                  return (
                    <article
                      key={`${serialNumber}__${index}`}
                      data-open-sr={hasOpenSR}
                      data-serial={serialNumber}
                      data-warranty={underWarranty}
                      data-supported={isSupported}
                      className={`${hasOpenSR ? 'has-open-sr' : ''} ${
                        isActive ? 'is-active' : ''
                      } ${underWarranty ? '' : 'warranty-expired'} ${
                        isSupported ? '' : 'not-supported'
                      } column is-4-tablet is-3-widescreen`}
                    >
                      <button
                        type="button"
                        className="device hover-grow"
                        ref={(deviceButtons) =>
                          (this.deviceButtons[index] = deviceButtons)
                        }
                        onClick={this.handleClick.bind(
                          this,
                          underWarranty,
                          isSupported,
                          serialNumber,
                          device,
                          hasOpenSR,
                        )}
                        data-serial={serialNumber}
                      >
                        {hasOpenSR && (
                          <div className="device__open-sr-msg include-icon-before ">
                            {dataDeviceListLables.hasOpenSR}
                          </div>
                        )}
                        <figure className="device__image">
                          <div
                            className={`img-container ${imgSrc}`}
                          ></div>
                        </figure>
                        <div className="device__content">
                          <h3
                            className="title is-5"
                            dangerouslySetInnerHTML={{
                              __html: deviceName,
                            }}
                          />
                          <h4 className="subtitle is-6">
                            {dataDeviceListLables.soundProcessor}
                          </h4>
                          <div className="device__subtitle">
                            <table className="table">
                              <tbody>
                                <tr className="serial__label">
                                  <th>
                                    {dataDeviceListLables.serialLabel}
                                    :
                                  </th>
                                  <td>{serialNumber}</td>
                                </tr>
                                <tr className="warranty__label">
                                  <th>
                                    {
                                      dataDeviceListLables.warrantyLabel
                                    }
                                    :
                                  </th>
                                  <td>
                                    {underWarranty
                                      ? warrantyStatus
                                      : dataDeviceListLables.warrantyExpired}
                                  </td>
                                </tr>
                                <tr className="colour__label">
                                  <th>
                                    {dataDeviceListLables.colorLabel}:
                                  </th>
                                  <td>{colour || 'N/A'}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </button>
                    </article>
                  );
                })
              ) : (
                <p>{dataDeviceListLables.noResultsLabel}</p>
              )}
            </div>
            {devices.length > 1 && (
              <div className="columns device__list">
                <article className="column is-4-tablet is-3-widescreen">
                  <Link
                    to="./service-request/identify-your-device-by-its-serial-number"
                    className="device hover-grow support__option device--help"
                    onClick={() => {
                      // Set referrerUrl as a cookie as it needs to be shared across sub domains
                      return Cookies.set(
                        'referrerUrl',
                        `${baseUrl}/service-request/identify-your-device-by-its-serial-number`,
                        {
                          path: '/',
                          domain: domainValue,
                          secure: true,
                          expires: getMinutes(15),
                        },
                      );
                    }}
                  >
                    <figure className="device__image">
                      <img src={help} alt="help" />
                    </figure>
                    <div className="device__content">
                      <Text
                        tag="h3"
                        className="title is-5 is-hidden-tablet"
                        field={helpBtnMobile}
                      />
                      <Text
                        tag="h3"
                        className="title is-5 is-hidden-mobile"
                        field={helpBtnDesktop}
                      />
                    </div>
                  </Link>
                </article>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default MyDeviceList;
