import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import AuthorizationError from './components/custom/AuthorizationError/AuthorizationError';
import config from './common/config';
import { authenticationService, environment } from './_services';
import getCochlearCountry from './utilities/getCochlearCountry';
import getSupportedCountry from './utilities/getSupportedCountry';
import languageMapping from './utilities/languageMapping';
import isEmpty from './utilities/isEmptyObject';

/* Page Content Data */
import dataDefault from './assets/error.json';

/* Config */
const { gql } = config;
const { scCd, apiKey, authToken } = gql[environment];
const baseURL = config.baseURL[environment];
const CochlearDotCom = config.CochlearDotCom[environment];
const linkSupportUrl = `${baseURL}us/en/home`;

// pass in obj from array
function getPair(toObject, fromObject) {
  // return new key value pair to new labels object
  return (toObject[fromObject.key.value] = fromObject.value.value);
}

class NotAuthorized extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      logout: false,
      error: false, // whether or not to show an error message
      errorType: '', // country, recipient, carer or networkError
      errorStatus: null, // server error returned from error data call
      errorData: {},
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  getLogoutUrl = () => {
    // Gets the cookie that was stored when the user clicked on login or raise service request
    const getReferrerUrl =
      Cookies.get('goBackUrl') || Cookies.get('referrerUrl');
    if (getReferrerUrl) {
      if (getReferrerUrl.includes('service-request')) {
        // because we cant log them out and return them to an authorized page area
        return linkSupportUrl; // device support homepage
      } else {
        return getReferrerUrl; // the page the url was on previously
      }
    }
    return CochlearDotCom; // cochlear.com homepage
  };

  handleBack = () => {
    const getBackUrl = this.getLogoutUrl();
    window.location.replace(getBackUrl);
  };

  handleLogout() {
    this.setState({
      logout: true,
    });

    // call logout service and get a response.
    authenticationService.logout(true);
    // authenticationService
    //   .logout()
    //   .catch((error) => {
    //     // console.log('error: ', error);
    //   })
    //   .finally(() => {
    //     const getLogoutUrl = this.getLogoutUrl();
    //     window.location.replace(
    //       `${CIM_RecipientLogout}?logoutURL=${getLogoutUrl}`,
    //     );
    //   });
  }

  setError(type, error, errorData) {
    const errorStatus =
      error && error.response ? error.response.status : '';
    this.setState({
      error: true,
      errorType: type,
      errorStatus: errorStatus || null,
      errorData: errorData || {},
    });
  }

  getErrorMessages(userErrorData) {
    const { countryCode, errorType, locale } = userErrorData;

    const language = languageMapping(locale, countryCode);

    // GQL query to return the screen image/message and button labels
    const queryData = JSON.stringify({
      query: `
      {
        labels: item(path: "/sitecore/content/drx/${countryCode.toUpperCase()}/Configuration/Error data/labels", language: "${language}") {
          results: children {
            key: field(name: "key") {
              value
            }
            value: field(name: "value") {
              value
            }
          }
        }
        screens: item(path: "/sitecore/content/drx/${countryCode.toUpperCase()}/Configuration/Error data/screens", language: "${language}") {
          results: children {
            name
            items: children {
              name
              title: field(name: "title") {
                value
              }
              text: field(name: "text") {
                value
              }
              image: field(name: "image") {
                value
              }
            }
          }
        }
      }     
      `,
    });

    const errorScreensUrl = `https://${scCd}/api/cochlear?sc_apikey=${apiKey}&sc_site=drx-us-en`;

    axios({
      method: 'post',
      url: errorScreensUrl,
      headers: {
        Authorization: authToken && `Basic ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: queryData,
    })
      .then((result) => {
        const { data } = result.data;
        this.setError(errorType, '', data);
      })
      .catch((error) => {
        // console.log(error);
        this.setError('networkError', error);
      });
  }

  componentDidMount() {
    // analyse token
    const { countryCode, userType, locale } =
      this.props.location.state || '';

    // returns true if user country returns a non-empty string
    const supportedCountry =
      countryCode && getSupportedCountry(countryCode) !== '';

    // if country is supported than display an error screen specific to their user account
    const errorType = supportedCountry
      ? userType.toLowerCase()
      : countryCode
      ? 'country'
      : 'networkError';

    const userErrorData = {
      countryCode,
      locale,
      errorType,
    };
    // call the error message api
    this.getErrorMessages(userErrorData);
    return false;
  }

  render() {
    const { logout, errorType, errorData, errorStatus } = this.state;
    const { countryCode } = this.props.location.state || '';
    // used when the api call to fetch error screens fails
    const { screens: defaultData, labels: defaultLabels } =
      dataDefault.data;

    // data retrieved from get call
    const { screens, labels } = errorData;

    // lets get the data we need for our error screen scenario and build our objects
    let screenData = {};
    const screenLabels = {};

    if (
      errorType &&
      errorType !== 'networkError' &&
      errorData &&
      !isEmpty(errorData)
    ) {
      const resultType = screens?.results.find(
        (obj) => obj.name === errorType,
      );
      const resultApp = resultType?.items.find(
        (obj) => obj.name === 'ds',
      );

      // create labels oject of strings
      // loop through array
      labels &&
        labels.results.forEach((value) => {
          // Do stuff with value or index
          getPair(screenLabels, value);
        });
      screenData = resultApp;
    } else {
      // network error, use default local error object
      defaultLabels.results.forEach((value) => {
        // Do stuff with value or index
        getPair(screenLabels, value);
      });
      screenData = defaultData.results[0].items[0];
    }

    // empty screen until errorType is defined
    if (!errorType) {
      return <></>;
    }

    const SkipLink = () => {
      return (
        <a
          id="skiplink"
          className="is-sr-only-focusable"
          href="#skipToContent"
        >
          <div className="container">
            <span className="skiplink-text">
              Skip to main content
            </span>
          </div>
        </a>
      );
    };

    const NavbarBasic = () => {
      return (
        <nav
          className="navbar navbar--basic"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a href={CochlearDotCom} className="navbar-item">
              <img
                src="https://assets.cochlear.com/api/public/content/89a2ac1ae1e046069e3b37a3bf54431b?v=015cdf30"
                alt="Cochlear"
              />
            </a>
          </div>
        </nav>
      );
    };

    return (
      <>
        <SkipLink />
        <NavbarBasic />
        <main className="site__content">
          <div id="skipToContent" tabIndex="-1" />

          <div className="error-message error-message--authorizer">
            <AuthorizationError
              errorData={screenData}
              labels={screenLabels}
              errorResponse={errorStatus}
              errorType={errorType}
            />

            {/* #5 - Recipient - < Back & Log me out > */}
            {/* #7 - Carer - < Back & Log me out > */}
            {/* #8 - Country - < Take me home & Log me out > */}
            {/* Default - Network Error - Device support home > */}
            <div className="error-message__button-control">
              {(errorType === 'recipient' ||
                errorType === 'carer') && (
                <button
                  type="button"
                  onClick={this.handleBack}
                  className="sdw__button sdw__button--cochlear-yellow sdw__button-back"
                >
                  <span>{screenLabels.backLabel}</span>
                </button>
              )}

              {errorType === 'country' && (
                <a
                  href={`${CochlearDotCom}${
                    countryCode && getCochlearCountry(countryCode)
                  }`}
                  className="sdw__button sdw__button--cochlear-yellow sdw__button-back"
                >
                  <span>{screenLabels.takeMeHomeLabel}</span>
                </a>
              )}

              {errorType !== 'networkError' && (
                <button
                  type="button"
                  onClick={this.handleLogout}
                  className={`sdw__button sdw__button--outline-purple sdw__button--logout has-spinner ${
                    logout ? 'is-submitting' : ''
                  }`}
                >
                  <span>{screenLabels.logMeOutLabel}</span>
                  {/* is submitting spinner  */}
                  <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </button>
              )}

              {errorType === 'networkError' && (
                <a
                  href={linkSupportUrl}
                  className="sdw__button sdw__button--cochlear-yellow"
                >
                  <span>{screenLabels.dsHomeLabel}</span>
                </a>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default NotAuthorized;
