import React from 'react';
import {
  RichText,
  Text,
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import AvailabilityStatus from './../custom/AvailabilityStatus/AvailabilityStatus';
import addRegToDevice from './../../utilities/addRegToDevice';
import ArticlesListing from '../ArticlesListing';

/**
 * Demonstrates advanced component techniques in JSS.
 * This example implements a simple-looking tabs component.
 * Each tab is itself a child component added to a placeholder defined on the tabs component.
 * The tab component introspects its child components to render the tab headings (i.e. the tab children render partial content in two places).
 * When this component is edited in Sitecore Experience Editor, the tabbing behavior is turned off and each tab stacks on top of each other
 * for easy inline editing.
 */

class TabsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      showAllTabs: false,
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(e, index) {
    e.preventDefault();
    this.setState({ activeTabIndex: index });
  }

  componentDidMount() {
    const showAllAtBreakpoint =
      this.props.params && this.props.params.showAllAtBreakpoint;
    if (showAllAtBreakpoint) {
      // Below this is where I'd like to use the media query to set the sidenavOPen to false. Just not sure how to achieve that
      this.match = window.matchMedia(`(min-width: 1024px)`);
      this.checkWidth = (e) => {
        if (e.matches) {
          if (showAllAtBreakpoint === 'desktop') {
            // update setstate if it has changed
            if (!this.state.showAllTabs) {
              this.setState({
                showAllTabs: true,
              });
            }
          }
          if (showAllAtBreakpoint === 'mobile') {
            // update setstate if it has changed
            if (this.state.showAllTabs) {
              this.setState({
                showAllTabs: false,
              });
            }
          }
        } else {
          if (showAllAtBreakpoint === 'mobile') {
            // update setstate if it has changed
            if (!this.state.showAllTabs) {
              this.setState({
                showAllTabs: true,
              });
            }
          }
          if (showAllAtBreakpoint === 'desktop') {
            // update setstate if it has changed
            if (this.state.showAllTabs) {
              this.setState({
                showAllTabs: false,
              });
            }
          }
        }
      };
      this.checkWidth(this.match);
      this.match.addListener(this.checkWidth);
    }
  }

  componentWillUnmount() {
    const showAllAtBreakpoint =
      this.props.params && this.props.params.showAllAtBreakpoint;
    if (showAllAtBreakpoint) {
      this.match.removeListener(this.checkWidth);
    }
  }

  render() {
    const { tabsPlaceholder, sitecoreContext } = this.props;
    const { showAllTabs } = this.state;
    let validTabIndex = 0;
    const isEditing = sitecoreContext && sitecoreContext.pageEditing;

    if (this.props.fields.displayArticles.value) {
      return (
        <React.Fragment>
          <Text
            tag="h2"
            className="title is-3"
            field={this.props.fields.heading}
          />
          <div className="tabs__container">
            <div className={`tabs ${this.props.params.style || ''}`}>
              <ul>
                {/*
                  When the page is editing, we hide the tab headings, because they are not updated when xEditor adds or removes a tab rendering.
                  Instead, we show the tab header inline with the tab contents (see Styleguide-Layout-Tabs-Tab).
                */}
                {!isEditing &&
                  (tabsPlaceholder || [])
                    .filter((tab) => tab.props && tab.props.fields)
                    .map((tab, index) => {
                      // used to append trademark icon to device name programmatically
                      const trademarkRequired =
                        tab.props.params.trademarkRequired;
                      const status =
                        tab.props.params.dependency &&
                        tab.props.params[tab.props.params.dependency]; // returns the value of the prop
                      const classStatus =
                        status === 'false'
                          ? 'is-offline'
                          : 'is-online'; // create the class name
                      // update the heading value
                      if (trademarkRequired === 'true') {
                        tab.props.fields.title.value = addRegToDevice(
                          tab.props.fields.title.value,
                          trademarkRequired,
                        );
                      }
                      return (
                        <li
                          className={`${
                            index === this.state.activeTabIndex
                              ? 'is-active'
                              : ''
                          }`}
                          key={`tab${index}`}
                        >
                          <a
                            onClick={(e) =>
                              this.setActiveTab(e, index)
                            }
                            href="/"
                          >
                            {tab.props.params.displayStatus ===
                              'true' && ( // do we want to show the red or green dot on the tab?
                              // text status label, eg: online, available, offline, etc
                              // show label: show the text label alongside the red/green dot
                              <AvailabilityStatus
                                label={''}
                                showLabel={false}
                                class={classStatus}
                              />
                            )}
                            <RichText
                              field={tab.props.fields.title}
                            />
                            <Text
                              tag="span"
                              field={tab.props.fields.subtitle}
                            />
                          </a>
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div className="tabs__content">
              <div className="tabs__content__list">
                {this.props.tabsPlaceholder[
                  this.state.activeTabIndex
                ].props.fields.articles.map((article, index) => {
                  return (
                    <ArticlesListing
                      key={index}
                      fields={article.fields}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Text
            tag="h2"
            className="title is-3"
            field={this.props.fields.heading}
          />
          <div className="tabs__container">
            <div className={`tabs ${this.props.params.style || ''}`}>
              <ul>
                {/*
                  When the page is editing, we hide the tab headings, because they are not updated when xEditor adds or removes a tab rendering.
                  Instead, we show the tab header inline with the tab contents (see Styleguide-Layout-Tabs-Tab).
                */}
                {!isEditing &&
                  (tabsPlaceholder || [])
                    .filter((tab) => tab.props && tab.props.fields)
                    .map((tab, index) => {
                      // used to append trademark icon to device name programmatically
                      const trademarkRequired =
                        tab.props.params.trademarkRequired;
                      const status =
                        tab.props.params.dependency &&
                        tab.props.params[tab.props.params.dependency]; // returns the value of the prop
                      const classStatus =
                        status === 'false'
                          ? 'is-offline'
                          : 'is-online'; // create the class name
                      // update the heading value
                      if (trademarkRequired === 'true') {
                        tab.props.fields.title.value = addRegToDevice(
                          tab.props.fields.title.value,
                          trademarkRequired,
                        );
                      }
                      return (
                        <li
                          className={`${
                            index === this.state.activeTabIndex
                              ? 'is-active'
                              : ''
                          }`}
                          key={`tab${index}`}
                        >
                          <a
                            onClick={(e) =>
                              this.setActiveTab(e, index)
                            }
                            href="/"
                          >
                            {tab.props.params.displayStatus ===
                              'true' && ( // do we want to show the red or green dot on the tab?
                              // text status label, eg: online, available, offline, etc
                              // show label: show the text label alongside the red/green dot
                              <AvailabilityStatus
                                label={''}
                                showLabel={false}
                                class={classStatus}
                              />
                            )}
                            <RichText
                              field={tab.props.fields.title}
                            />
                            <Text
                              tag="span"
                              field={tab.props.fields.subtitle}
                            />
                          </a>
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div className="tabs__content">
              {(tabsPlaceholder || []).map((tab, index) => {
                const isValid = tab.props && tab.props.fields;

                // allow experience editor markup components to render
                if (!isValid && isEditing) return tab;

                validTabIndex += 1;

                // we render the tab either if it's active - or we're editing,
                // in which case we stack all tabs for visual editing
                if (
                  this.state.activeTabIndex === validTabIndex - 1 ||
                  isEditing ||
                  showAllTabs
                ) {
                  return tab;
                }

                return null;
              })}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

// This is a _higher order component_ that will wrap our component and inject placeholder
// data into it as a prop (in this case, props.tabsPlaceholder).
// this another option compared to using the <Placeholder> component;
// in this case, chosen because we primarily need access to the _data_
// of the placeholder.
const tabsComponentWithPlaceholderInjected = withPlaceholder({
  placeholder: 'jss-tabs',
  prop: 'tabsPlaceholder',
})(TabsSection);

// We need to know if experience editor is active, to disable the dynamic tab behavior for editing.
// Using the same technique as injecting the placeholder, we wrap the component again to inject the
// `sitecoreContext` prop.
const tabsWithPlaceholderAndSitecoreContext = withSitecoreContext()(
  tabsComponentWithPlaceholderInjected,
);

export default tabsWithPlaceholderAndSitecoreContext;
