import React from 'react';
import { Link } from 'react-router-dom';

// Apply capital letter to the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const BreadcrumbSection = (props) => {
  let parts = [];
  let parent = props.fields?.data?.item?.parent;

  parts.push({
    url: props.fields?.data?.item?.url,
    name: props.fields?.data?.item?.name,
    field: props.fields?.data?.item?.field,
  });

  // case to handle "r" route, and home
  while (parent) {
    if (
      parent.template.id === '02B39EAB6F8D5301853088A31C57B08D' &&
      parent.name !== 'r'
    ) {
      parts.push({
        url: parent.url,
        name: parent.name,
        field: parent.field,
      });
    }

    if (parent.template.id === '4F8C8E7402184FC1AE43507BB81D40E2') {
      parts.push({
        url: parent.url + 'home',
        name: 'Support home',
      });
      break;
    }

    parent = parent.parent;
  }

  return (
    <React.Fragment>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          {parts.reverse().map((d, index) => {
            // replace dashes taken from page name (eg, kanso-2) and replace it with a space.
            const title = d.name.replace(/-/g, ' ');
            if (!d.url) {
              if (title === 'home') return null;
              return (
                <li key={index} className="is-active">
                  <span
                    data-ecategory="Breadcrumb"
                    aria-current="page"
                  >
                    {d.field?.value || capitalizeFirstLetter(title)}
                  </span>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <Link data-ecategory="Breadcrumb" to={d.url}>
                    {d.field?.value || capitalizeFirstLetter(title)}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default BreadcrumbSection;
