import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { Formik, Form } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import config from './../../common/config';
import getMinutes from './../../utilities/getMinutes';

import submitSchemaErrorMessages from './submitSchemaErrorMessages';
import {
  authenticationService,
  environment,
} from './../../_services';

import Confirmation from './../custom/service-request/Confirmation/Confirmation';
import ErrorScreen from './../custom/ErrorScreen/ErrorScreen';
import Step1 from './../custom/service-request/Step1/Step1';
import Step2 from './../custom/service-request/Step2/Step2';
import Step3 from './../custom/service-request/Step3/Step3';
import Step4 from './../custom/service-request/Step4/Step4';
import Step5 from './../custom/service-request/Step5/Step5';
import FlagItem from './../custom/FlagItem/FlagItem';
import errorImg from './../../assets/graphics/error-img@2x.png';

const { authenticate, cookieDomain } = config;
const patientsAPI = authenticate[environment].patients;
const domainValue = cookieDomain[environment];

const updateUserToken = (token) => {
  // update service
  authenticationService.updateCurrentUser(token);

  // update cookie
  Cookies.set('currentUser', token, {
    domain: domainValue,
    secure: true,
    expires: getMinutes(120),
  });
};

const fetchDataFromApi = async (url, token) => {
  try {
    const response = await axios.get(url, {
      params: {},
      headers: { Authorization: 'Bearer ' + token },
    });

    const newToken =
      response.headers['x-amzn-remapped-authorization'];

    if (newToken) {
      updateUserToken(newToken);
    }
    return response && response.data;
  } catch (error) {
    throw error;
  }
};

class ServiceRequestForm extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      profileIsLoading: false,
      serialNumber: '',
      device: {},
      phoneNumber: '',
      countryDialingCodeValue: '',
      currentStep: 1,
      showConfirmation: false,
      showError: false,
      errorStatus: '',
      enteredValues: {},
      accountData: {},
      addressData: {},
      accountDataError: null,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.srForm = React.createRef();
    this.userDetails = React.createRef();
  }

  handleEdit = (dataFromChild, validateForm) => {
    this.setState(
      {
        currentStep: dataFromChild,
      },
      () => {
        if (validateForm) {
          validateForm().then((validation) => {
            // updates the isValid prop as we navigate from step to step
            return true;
          });
        }
      },
    );
  };

  handleNext = (validateForm) => {
    this._next(validateForm);
  };

  handleSelect = (serialNumber, device) => {
    this.setState({
      serialNumber,
      device: {
        customerFacingName: device.customerFacingName,
        productName: device.productName,
        productDescription: device.productDescription,
        deviceType: device.deviceType,
        earSide: device.earSide,
        fittingDate: device.fittingDate,
        latestWarrantyDate: device.latestWarrantyDate,
      },
    });
    if (this.state.currentStep === 1) {
      const userDetails = document.getElementById('userDetails');
      window.scrollTo(0, userDetails.offsetTop);
      this._next();
    }
  };

  handleSubmit = (formData) => {
    // event.preventDefault();

    this.setState({
      enteredValues: formData,
    });

    const { serialNumber, device, accountData, enteredValues } =
      this.state;

    const data = {
      serialNumber,
      productName: device.productName,
      customerFacingName: device.customerFacingName,
      productDescription: device.productDescription,
      deviceType: device.deviceType,
      earSide: device.earSide,
      fittingDate: device.fittingDate,
      lastWarrantyDate: device.latestWarrantyDate,
      firstName: enteredValues.firstName,
      lastName: enteredValues.lastName,
      mobilePhone: enteredValues.countryDialingCode
        ? enteredValues.countryDialingCode.value +
          '' +
          enteredValues.mobilePhone
        : '',
      email: accountData.email,
      clinic: enteredValues.clinicName,
      audiologist: enteredValues.clinicianName,
      problemTopic: enteredValues.problemTopic,
      problemDescription: enteredValues.problemDescription,
      termsConditionTicked: enteredValues.terms,
      shippingAddress: {
        address1: enteredValues.addressLine1,
        address2: enteredValues.addressLine2,
        address3: enteredValues.addressLine3,
        city: enteredValues.city,
        state: enteredValues.state,
        postcode: enteredValues.zipCode,
        country: config.countryName,
      },
    };

    const btn = document.getElementById('submitBtn');
    btn.disabled = true; // stop user from making multiple submissions of the same comment
    btn.classList.add('is-submitting');

    const token = this.props.params.currentUser;

    axios
      .post(
        `${patientsAPI}/me/devices/service-requests?id=${this.props.params.userId}`,
        data,
        {
          headers: { Authorization: 'Bearer ' + token },
        },
      )
      // axios.post(submitUrl, data)
      .then((response) => {
        if (this._isMounted) {
          if (response.status === 200) {
            window.dataLayer.push({ event: 'sr-successful' });
            btn.classList.remove('is-submitting');
            btn.disabled = false;
            this.setState({
              showConfirmation: true,
            });
            // hide the intro
            document.getElementsByClassName(
              'hero-body',
            )[0].style.display = 'none';
            // scroll to the top
            window.scrollTo(0, 0);
          } else {
            window.dataLayer.push({ event: 'sr-unsuccessful' });
            this.setState({
              showError: true,
              errorStatus: response.status,
            });
            // hide the intro
            document.getElementsByClassName(
              'hero-body',
            )[0].style.display = 'none';
            // scroll to the top
            window.scrollTo(0, 0);
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          const response = error.response;
          if ([401, 403].indexOf(response.status) !== -1) {
            authenticationService.renew();
            return false;
          }
          this.setState({
            showError: true,
            errorStatus: response.status || error,
          });
          // hide the intro
          document.getElementsByClassName(
            'hero-body',
          )[0].style.display = 'none';
          // scroll to the top
          window.scrollTo(0, 0);
        }
      });
  };

  _next = (validateForm) => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 4 ? 5 : currentStep + 1;
    this.setState(
      {
        currentStep: currentStep,
      },
      () => {
        if (validateForm) {
          validateForm().then((validation) => {
            // updates the isValid prop as we navigate from step to step
            return true;
          });
        }
      },
    );
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  updateMobile() {
    let phoneNumber = '';
    let countryDialingCodeValue = '';
    // check the user has a mobile phone listed
    let mobile = this.state.accountData.phones.mobile;

    // find the value for a primary mobile phone
    let mobilePhone = mobile
      ? `${mobile.callingCode}${mobile.number}`
      : undefined;

    if (mobilePhone && mobilePhone.charAt(0) !== '+') {
      mobilePhone = `+${mobilePhone}`;
    }

    if (mobilePhone) {
      phoneNumber = parsePhoneNumberFromString(mobilePhone);
      countryDialingCodeValue = {
        value: `+${phoneNumber.countryCallingCode}`,
        label: (
          <FlagItem
            code={
              phoneNumber?.country?.toLowerCase() ||
              mobile?.countryCode?.toLowerCase()
            }
            dial_code={`+${phoneNumber?.countryCallingCode}`}
          />
        ),
      };
    }
    this.setState({ countryDialingCodeValue, phoneNumber });
  }

  componentDidMount() {
    this._isMounted = true;

    // gtm tracking
    if (typeof window !== 'undefined') {
      window.dataLayer.push({ event: 'formload' });
    }

    var self = this;
    self.setState({ profileIsLoading: true });
    const token = authenticationService.currentUserValue;
    // Make a request for a user with a given ID
    const decoded = token && jwtDecode(token);
    const id = decoded['https://www.cochlear.com/cochlear_id'];
    const fetchData = async () => {
      try {
        const [accountData, addressData] = await Promise.all([
          fetchDataFromApi(`${patientsAPI}/me?id=${id}`, token),
          fetchDataFromApi(
            `${patientsAPI}/me/address?id=${id}`,
            token,
          ),
        ]);

        if (this._isMounted) {
          // address returns an array of addresses. it could be empty
          // get the address tagged as shipping if it exists
          const addressArray = addressData.data.address;
          let shippingAddress = addressArray?.filter(
            (x) => x.isShipping,
          );
          self.setState(
            {
              accountData: accountData.data.account,
              addressData: shippingAddress[0]?.value?.address,
            },
            () => {
              this.updateMobile();
            },
          );
        }
      } catch (err) {
        if (this._isMounted) {
          const response = err.response;
          if ([401, 403].indexOf(response.status) !== -1) {
            authenticationService.renew();
            return false;
          }
          self.setState({
            accountDataError: err,
          });
        }
      } finally {
        if (this._isMounted) {
          self.setState({
            profileIsLoading: false,
          });
        }
      }
    };

    fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      profileIsLoading,
      showConfirmation,
      showError,
      errorStatus,
      currentStep,
      serialNumber,
      device,
      enteredValues,
      accountData,
      addressData,
      phoneNumber,
      countryDialingCodeValue,
    } = this.state;

    const submitSchemaStep1 = {};
    const submitSchemaStep2 = Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(submitSchemaErrorMessages.required.firstName),
      lastName: Yup.string()
        .trim()
        .required(submitSchemaErrorMessages.required.lastName),
      countryDialingCode: Yup.string().required(
        submitSchemaErrorMessages.required.countryDialingCode,
      ),
      mobilePhone: Yup.string()
        .trim()
        .matches(
          /^[0-9]{1,15}$/,
          submitSchemaErrorMessages.matches.mobilePhone,
        )
        .required(submitSchemaErrorMessages.required.mobilePhone),
    });

    const submitSchemaStep3 = Yup.object().shape({
      clinicName: Yup.string()
        .trim()
        .required(submitSchemaErrorMessages.required.clinicName),
      clinicianName: Yup.string()
        .trim()
        .required(submitSchemaErrorMessages.required.clinicianName),
    });

    const submitSchemaStep4 = Yup.object().shape({
      problemTopic: Yup.string().required(
        submitSchemaErrorMessages.required.problemTopic,
      ),
      problemDescription: Yup.string()
        .trim()
        .required(
          submitSchemaErrorMessages.required.problemDescription,
        ),
    });

    const submitSchemaStep5 = Yup.object().shape({
      addressLine1: Yup.string()
        .trim()
        .max(35, submitSchemaErrorMessages.max.addressLine)
        .required(submitSchemaErrorMessages.required.addressLine),
      addressLine2: Yup.string()
        .trim()
        .max(35, submitSchemaErrorMessages.max.addressLine),
      addressLine3: Yup.string()
        .trim()
        .max(35, submitSchemaErrorMessages.max.addressLine),
      city: Yup.string()
        .trim()
        .required(submitSchemaErrorMessages.required.city),
      state: Yup.string().required(
        submitSchemaErrorMessages.required.state,
      ),
      zipCode: Yup.string()
        .trim()
        .matches(
          /^[0-9]{5}(?:-[0-9]{4})?$/,
          submitSchemaErrorMessages.matches.zipCode,
        )
        .required(submitSchemaErrorMessages.required.zipCode),
      terms: Yup.bool()
        .required(submitSchemaErrorMessages.required.terms)
        .oneOf([true], submitSchemaErrorMessages.required.terms),
    });
    const submitSchema = [
      submitSchemaStep1,
      submitSchemaStep2,
      submitSchemaStep3,
      submitSchemaStep4,
      submitSchemaStep5,
    ];

    // error after submission
    const { errorScreenTitle, errorScreenText } = this.props.fields;

    // pass it into a new object
    const errorText = {
      title: errorScreenTitle.value,
      text: errorScreenText.value,
    };

    // Error on submission

    if (showError) {
      return (
        <div className="columns is-centered">
          <div className="column is-10-desktop">
            <ErrorScreen
              chat={this.props.params.chat}
              errorStatus={errorStatus}
              image={errorImg}
              onlineSupport={this.props.params.onlineSupport}
              text={errorText}
            />
          </div>
        </div>
      );
    }

    // Steps Text and Labels
    const {
      stepTitle1,
      stepTitle2,
      stepTitle3,
      stepTitle4,
      stepTitle5,
      stepTitle6,
      titleTermsAndConditions,
      termsAndConditionsList,
      helpBtnMobile,
      helpBtnDesktop,
      selectLabel,
      selectLabel2,
      optionalLabel,
      editBtn,
      nextStep2,
      nextStep3,
      nextStep4,
      nextStep5,
    } = this.props.fields;

    // Form Labels
    const {
      formLabelFirstName,
      formLabelLastName,
      formLabelEmail,
      formLabelNumberType,
      formLabelNumberTypeOption1,
      formLabelCountryDialingCode,
      formLabelCountryMobilePhone,
      formLabelClinicName,
      formLabelClinicianName,
      formLabelProblemTopic,
      formLabelProblemTopicOption1,
      formLabelProblemTopicOption2,
      formLabelProblemTopicOption3,
      formLabelProblemTopicOption4,
      formLabelProblemDescription,
      formLabelProblemDescriptionPlaceholder,
      formLabelAddressLine1,
      formLabelAddressLine2,
      formLabelAddressLine3,
      formLabelCity,
      formLabelState,
      formLabelZipCode,
      formLabelTermsAndConditions,
      formLabelPrivacy,
    } = this.props.fields;

    // Confirmation Screen Labels and Text
    const {
      confirmationImage,
      confirmationTitleBeforeFirstName,
      confirmationTitleAfterFirstName,
      confirmationLead,
      confirmationSectionTitle1,
      confirmationSectionTitle2,
      confirmationSectionTitle3,
      confirmationSectionTitle4,
      confirmationSectionTitle5,
      confirmationSectionLabel1,
      confirmationSectionLabel2,
      confirmationSectionLabel3,
      confirmationSectionLabel4,
      confirmationBtnLabel,
      confirmationBtnLink,
    } = this.props.fields;

    const stepLabels2 = {
      formLabelFirstName,
      formLabelLastName,
      formLabelEmail,
      formLabelNumberType,
      formLabelNumberTypeOption1,
      formLabelCountryDialingCode,
      formLabelCountryMobilePhone,
    };

    const stepLabels3 = {
      formLabelClinicName,
      formLabelClinicianName,
    };

    const stepLabels4 = {
      formLabelProblemTopic,
      formLabelProblemTopicOption1,
      formLabelProblemTopicOption2,
      formLabelProblemTopicOption3,
      formLabelProblemTopicOption4,
      formLabelProblemDescription,
      formLabelProblemDescriptionPlaceholder,
    };

    const stepLabels5 = {
      formLabelAddressLine1,
      formLabelAddressLine2,
      formLabelAddressLine3,
      formLabelCity,
      formLabelState,
      formLabelZipCode,
      formLabelTermsAndConditions,
      formLabelPrivacy,
    };

    const comfirmationScreenText = {
      confirmationTitleBeforeFirstName,
      confirmationTitleAfterFirstName,
      confirmationLead,
      confirmationSectionTitle1,
      confirmationSectionTitle2,
      confirmationSectionTitle3,
      confirmationSectionTitle4,
      confirmationSectionTitle5,
      confirmationSectionLabel1,
      confirmationSectionLabel2,
      confirmationSectionLabel3,
      confirmationSectionLabel4,
      confirmationBtnLabel,
      confirmationBtnLink,
    };

    const initialValues = {
      firstName: accountData.firstName || '',
      lastName: accountData.lastName || '',
      countryDialingCode: countryDialingCodeValue,
      mobilePhone: phoneNumber ? phoneNumber.nationalNumber : '',
      email: accountData.email || '',
      clinicName: accountData.clinic || '',
      clinicianName: '',
      problemTopic: '',
      problemDescription: '',
      addressLine1: addressData?.street ? addressData.street[0] : '',
      addressLine2: addressData?.street ? addressData.street[1] : '',
      addressLine3: addressData?.street ? addressData.street[2] : '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      zipCode: addressData?.postalCode || '',
      terms: '',
    };

    return (
      <div
        className={`columns ${showConfirmation ? 'is-centered' : ''}`}
      >
        <div className="column is-10-desktop">
          {!showConfirmation ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={submitSchema[currentStep - 1]}
              isInitialValid={submitSchema[1].isValidSync(
                initialValues,
              )} // will correctly update the step 2 isValid boolean value
              onSubmit={(values) => {
                // same shape as initial values
                this.handleSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                validateForm,
                setTouched,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                isValid,
              }) => (
                <Form noValidate id="srForm">
                  <ol>
                    <Step1
                      handleSelect={this.handleSelect}
                      selectedProcessor={serialNumber}
                      profileIsLoading={profileIsLoading}
                      currentUser={this.props.params.currentUser}
                      userId={this.props.params.userId}
                      title={stepTitle1}
                      helpBtnMobile={helpBtnMobile}
                      helpBtnDesktop={helpBtnDesktop}
                    />
                    <Step2
                      id="userDetails"
                      ref={this.userDetails}
                      currentStep={currentStep}
                      handleNext={this.handleNext}
                      handleEdit={this.handleEdit}
                      errors={errors}
                      touched={touched}
                      validateForm={validateForm}
                      setTouched={setTouched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      values={values}
                      title={stepTitle2}
                      labels={stepLabels2}
                      edit={editBtn}
                      nextBtnLabel={nextStep2}
                      selectLabel={selectLabel}
                      isValid={isValid}
                    />
                    <Step3
                      currentStep={currentStep}
                      handleNext={this.handleNext}
                      handleEdit={this.handleEdit}
                      errors={errors}
                      touched={touched}
                      validateForm={validateForm}
                      setTouched={setTouched}
                      values={values}
                      title={stepTitle3}
                      labels={stepLabels3}
                      edit={editBtn}
                      nextBtnLabel={nextStep3}
                      isValid={isValid}
                    />
                    <Step4
                      currentStep={currentStep}
                      handleNext={this.handleNext}
                      handleEdit={this.handleEdit}
                      errors={errors}
                      touched={touched}
                      validateForm={validateForm}
                      setTouched={setTouched}
                      values={values}
                      title={stepTitle4}
                      labels={stepLabels4}
                      edit={editBtn}
                      nextBtnLabel={nextStep4}
                      selectLabel={selectLabel2}
                      isValid={isValid}
                    />
                    <Step5
                      currentStep={currentStep}
                      errors={errors}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      title={stepTitle5}
                      titleTermsAndConditions={
                        titleTermsAndConditions
                      }
                      labels={stepLabels5}
                      nextBtnLabel={nextStep5}
                      optionalLabel={optionalLabel}
                      selectLabel={selectLabel2}
                      termsAndConditionsList={termsAndConditionsList}
                      isValid={isValid}
                    />
                    <li
                      className="step__panel"
                      style={{ borderLeft: '0' }}
                    >
                      <Text
                        tag="h2"
                        className="has-text-future title is-3"
                        field={stepTitle6}
                      />
                    </li>
                  </ol>
                </Form>
              )}
            </Formik>
          ) : (
            <Confirmation
              serialNumber={serialNumber}
              device={device}
              firstName={enteredValues.firstName}
              lastName={enteredValues.lastName}
              countryDialingCode={
                enteredValues.countryDialingCode
                  ? enteredValues.countryDialingCode.value
                  : ''
              }
              mobilePhone={enteredValues.mobilePhone}
              email={accountData.email}
              clinicName={enteredValues.clinicName}
              clinicianName={enteredValues.clinicianName}
              problemDescription={enteredValues.problemDescription}
              addressLine1={enteredValues.addressLine1}
              addressLine2={enteredValues.addressLine2}
              addressLine3={enteredValues.addressLine3}
              city={enteredValues.city}
              state={enteredValues.state}
              zipCode={enteredValues.zipCode}
              comfirmationScreenText={comfirmationScreenText}
              confirmationImage={confirmationImage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ServiceRequestForm;
