import React from 'react';

import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';

import UniversalHeader from './UniversalHeader';
import Navigation from './Navigation';
import Footer from './Footer';
import Search from './components/custom/Search/Search';
import ModalNewFeatures from './components/custom/ModalNewFeatures/ModalNewFeatures';
import ChatSwitch from './components/custom/ChatSwitch/ChatSwitch';

import config from './common/config';

import './_sass/main.scss';
import './assets/app.css';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chat: false,
      onlineSupport: false,
      utcOffset: config.utcOffset,
      prevScrollpos:
        typeof window === 'undefined' ? 0 : window.pageYOffset,
      visible: true,
      isActiveProfile: false,
      headerLogin: {},
      isProfileEnabled: false,
      showFeaturesModal: false,
      featuresModalContent: {},
      showPopOver: false,
      showSearch: false,
      feature: {},
    };

    /*
    Bind our childHandler function to this context
    that will get called from our Child component
    */
    this.childHandler = this.childHandler.bind(this);
    this.calcTime = this.calcTime.bind(this);
    this.calcOnlineStatus = this.calcOnlineStatus.bind(this);
    this.getOnlineStatus = this.getOnlineStatus.bind(this);
    this.setHeaderLogin = this.setHeaderLogin.bind(this);
    this.toggleHeaderFooterFeatures =
      this.toggleHeaderFooterFeatures.bind(this);

    // whatsnew popup
    this.toggleFeaturesModal = this.toggleFeaturesModal.bind(this);
    this.setWhatsnewContent = this.setWhatsnewContent.bind(this);
    this.toggleWhatsnewPopOver =
      this.toggleWhatsnewPopOver.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  /*
    Return features from the header footer
  */
  toggleHeaderFooterFeatures(obj) {
    if (typeof obj === 'object' && obj !== null) {
      this.setState({
        features: obj,
      });
    }
  }

  /*
    Return chat status from child
  */
  childHandler(dataFromChild) {
    if (dataFromChild !== this.state.chat) {
      this.setState({
        chat: !this.state.chat,
      });
    }
  }

  // given an offset value, gets current time
  calcTime(offset) {
    let d = new Date();
    let utc = d.getTime() + d.getTimezoneOffset() * 60000;
    let nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  // given a datetime, will determine if it falls within current business hours as defined in the function
  calcOnlineStatus(nd) {
    let isOnline =
      nd.getDay() >= 1 &&
      nd.getDay() <= 5 &&
      nd.getHours() >= 6 &&
      nd.getHours() < 18; // M-F 6am-6pm
    if (!isOnline && nd.getDay() === 6) {
      // Saturdays 8am-12pm
      isOnline = nd.getHours() >= 8 && nd.getHours() < 12;
    }
    return isOnline;
  }

  getOnlineStatus() {
    const getLocalTime = this.calcTime(this.state.utcOffset);
    return this.calcOnlineStatus(getLocalTime);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({
      onlineSupport: this.getOnlineStatus(),
    });
    this.getOnlineStatusTimer = setInterval(() => {
      if (this.getOnlineStatus() !== this.state.onlineSupport) {
        this.setState({
          onlineSupport: !this.state.onlineSupport,
        });
      }
    }, 120000); // check set to 2 minutes
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.getOnlineStatusTimer);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible =
      currentScrollPos > 10 ? prevScrollpos > currentScrollPos : true;
    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  handleProfileToggle = () => {
    const { isActiveProfile } = this.state;
    const currentState = isActiveProfile;
    this.setState({
      isActiveProfile: !currentState,
    });
  };

  // pass api call from Universal Header to Navigation component
  setHeaderLogin = (headerLogin, isProfileEnabled) => {
    this.setState({
      headerLogin,
      isProfileEnabled,
    });
  };

  // triggered from Profile Dropdown or Pop Over
  toggleFeaturesModal() {
    const element = document.getElementsByTagName('HTML')[0];
    element.classList.toggle('is-clipped');
    const { showFeaturesModal } = this.state;
    const currentState = showFeaturesModal;
    this.setState({
      showFeaturesModal: !currentState,
    });
  }

  setWhatsnewContent(data) {
    if (data) {
      this.setState({
        featuresModalContent: data,
      });
    }
  }

  // has to update state and pass as prop to
  // ProfileDropdown component so both instances behave the same
  toggleWhatsnewPopOver(bool) {
    this.setState({
      showPopOver: bool,
    });
  }

  toggleSearch(event = undefined) {
    if (event) event.preventDefault();
    const element = document.getElementsByTagName('HTML')[0];
    element.classList.toggle('is-search');
    const { showSearch } = this.state;
    const currentState = showSearch;
    this.setState({
      showSearch: !currentState,
    });
  }

  render() {
    const { route, currentUser, userId, privatePath, routeParams } =
      this.props;
    const {
      chat,
      onlineSupport,
      isActiveProfile,
      visible,
      headerLogin,
      isProfileEnabled,
      showPopOver,
      showFeaturesModal,
      featuresModalContent,
      showSearch,
      features,
    } = this.state;

    return (
      <React.Fragment>
        {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        <Helmet>
          <title>
            {route.fields?.pageTitle?.value ||
              'Cochlear device support'}
          </title>
          <meta
            name="title"
            content={
              route.fields?.pageTitle?.value ||
              'Cochlear device support'
            }
          />
          <meta
            name="description"
            content={
              route.fields?.metaDescription?.value ||
              'Get help to solve common issues related to your sound processor.'
            }
          />
          <meta
            name="keywords"
            content={
              route.fields?.metaKeywords?.value ||
              'Cochlear device support, common sound processor issues, sound processor issues, most common Nucleus 7 Sound Processor issues, most common Nucleus 6 Sound Processor issues, most common Kanso Sound Processor issues, most common Baha Sound Processor issues, Nucleus 7 Sound Processor issues, Nucleus 6 Sound Processor issues, Kanso Sound Processor issues, Baha Sound Processor issues'
            }
          />
        </Helmet>
        {!['LOCAL', 'DEV', 'SIT'].includes(config.env) && (
          <Helmet>
            <script
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              data-document-language="true"
              type="text/javascript"
              charset="UTF-8"
              data-domain-script="e2b3c6ba-eed9-49e5-aa55-24fff8f654d2"
              onload="function OptanonWrapper() { window.dataLayer.push( { event: 'OneTrustGroupsUpdated' } );}"
            ></script>
          </Helmet>
        )}

        <a
          id="skiplink"
          className="is-sr-only-focusable"
          href="#skipToContent"
        >
          <div className="container">
            <span className="skiplink-text">
              Skip to main content
            </span>
          </div>
        </a>

        {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
        */}
        <VisitorIdentification />
        {showFeaturesModal && (
          <ModalNewFeatures
            closeCallback={this.toggleFeaturesModal}
            data={featuresModalContent}
          />
        )}
        <header
          className={`hero ${
            (route.fields &&
              route.fields.heroClass &&
              route.fields.heroClass.value) ||
            'hero--default'
          }`}
        >
          <div
            className={`sticky ${
              !visible ? 'is-scrolling-down' : ''
            } ${
              typeof window !== 'undefined' &&
              window.pageYOffset !== 0
                ? 'is-scrolling'
                : ''
            }
              ${isActiveProfile ? 'is-profile-open' : ''}
            `}
          >
            <UniversalHeader
              currentUser={currentUser}
              isActiveProfile={isActiveProfile}
              onChildClick={this.handleProfileToggle}
              onHeaderLoginMounted={this.setHeaderLogin}
              headerLogin={headerLogin}
              isProfileEnabled={isProfileEnabled}
              privatePath={privatePath}
              callbackWhatsnew={this.toggleFeaturesModal}
              callbackWhatsnewContent={this.setWhatsnewContent}
              callbackShowPopOver={this.toggleWhatsnewPopOver}
              callbackHeaderFooterFeatures={
                this.toggleHeaderFooterFeatures
              }
              showPopOver={showPopOver}
              routeParams={routeParams}
              features={features}
            />
            {/* Hero head: will stick at the top */}
            <div className="hero-head">
              <Navigation
                onlineSupport={onlineSupport.toString()}
                chat={chat.toString()}
                serviceRequests="true"
                currentUser={currentUser}
                isActiveProfile={isActiveProfile}
                onChildClick={this.handleProfileToggle}
                headerLogin={headerLogin}
                isProfileEnabled={isProfileEnabled}
                privatePath={privatePath}
                callbackWhatsnew={this.toggleFeaturesModal}
                callbackWhatsnewContent={this.setWhatsnewContent}
                callbackShowPopOver={this.toggleWhatsnewPopOver}
                showPopOver={showPopOver}
                callbackToggleSeach={this.toggleSearch}
                showSearch={showSearch}
                route={route}
                features={features}
              />
            </div>

            {/* Search content */}
            {showSearch && (
              <Search callbackToggleSeach={this.toggleSearch} />
            )}
          </div>

          {/* Hero content */}
          {route.placeholders['jss-hero'] && (
            <Placeholder name="jss-hero" rendering={route} />
          )}
        </header>
        {/* root placeholder for the app, which we add components to using route data */}
        <main className="site__content">
          <div id="skipToContent" tabIndex="-1" />

          {/* 
          chat - is chat online or offline
          requests - is services requests available or not 
          onlineSupport - MDT (Mountain Daylight Time) UTC/GMT -6 hours  
        */}

          <Placeholder
            name="jss-main"
            rendering={route}
            onlineSupport={onlineSupport.toString()}
            chat={chat.toString()}
            serviceRequests="true"
            currentUser={currentUser}
            userId={userId}
          />
        </main>
        <Footer
          route={route}
          onlineSupport={onlineSupport.toString()}
          chat={chat.toString()}
          serviceRequests="true"
        />
        <ChatSwitch action={this.childHandler} />
      </React.Fragment>
    );
  }
}

export default Layout;
