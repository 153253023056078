import React, { useEffect } from 'react';

import config from './../../../common/config';
import {
  authenticationService,
  environment,
} from './../../../_services';

const chatConfig = config.chatSFHC[environment];

// text that appears on the chat button when offline
// this text is used by our script to check if agent is offline
const DISABLED_MINIMIZED_TEXT = 'Agent Offline';

const activeAgentUpdate = (action) => {
  const findChatBtn = document.querySelectorAll(
    '.embeddedServiceHelpButton',
  );

  if (findChatBtn.length > 0) findChatBtn[0].classList.add('online');
  // update the components using callback prop to show it is online

  action(true);
};

const ChatSFHC = ({ action }) => {
  const initESW = (gslbBaseURL, token = undefined) => {
    // callback function used to return when chat is online/offline to other components

    // window.embedded_svc.settings.devMode = false;
    window.embedded_svc.settings.displayHelpButton = true; // Or false
    window.embedded_svc.settings.language = ''; // For example, enter 'en' or 'en-US'
    if (token) {
      window.embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: 'JWT_Token__c',
          value: token,
          displayToAgent: true,
          transcriptFields: ['JWT_Token__c'],
        },
      ];
    }
    window.embedded_svc.settings.chatbotAvatarImgURL =
      'https://assets.cochlear.com/api/public/content/6f23d3be1f914de68ce5e872f4ea0c37?v=6f5b69ba';
    window.embedded_svc.settings.avatarImgURL =
      'https://assets.cochlear.com/api/public/content/6f23d3be1f914de68ce5e872f4ea0c37?v=6f5b69ba';
    window.embedded_svc.settings.waitingStateBackgroundImgURL =
      'https://assets.cochlear.com/api/public/content/103890fbd40543649576d05046a7693f?v=16d737b3';
    window.embedded_svc.settings.smallCompanyLogoImgURL =
      'https://assets.cochlear.com/api/public/content/cf2fc156e74e4aa99c6d2eee382dc63b';

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.settings.disabledMinimizedText =
      DISABLED_MINIMIZED_TEXT;
    window.embedded_svc.settings.defaultMinimizedText = 'Contact us';
    window.embedded_svc.settings.loadingText = 'Loading...';

    // window.embedded_svc.settings.targetElement = 'main.site__content';

    window.embedded_svc.init(
      `https://${chatConfig.sfEnv}.my.salesforce.com`,
      `https://${chatConfig.sfEnv}.my.salesforce-sites.com/`,
      gslbBaseURL,
      chatConfig.salesforceId,
      chatConfig.buttonChatName,
      {
        baseLiveAgentContentURL: `https://${chatConfig.baseLiveAgentContentURL}.salesforceliveagent.com/content`,
        deploymentId: chatConfig.buttonDeployId,
        buttonId: chatConfig.buttonId,
        baseLiveAgentURL: `https://${chatConfig.baseLiveAgentURL}.salesforceliveagent.com/chat`,
        eswLiveAgentDevName: chatConfig.buttonEswLiveAgentDevName,
        isOfflineSupportEnabled: chatConfig.isOfflineSupportEnabled,
      },
    );
  };

  useEffect(() => {
    // Script tag is loaded!
    // Perform function here

    const token = authenticationService.currentUserValue;

    // workaround to modify response from salesforce
    const originalJSONParse = JSON.parse;

    JSON.parse = (str, ...args) => {
      const originalValue = originalJSONParse(str, ...args);

      try {
        if (
          !(originalValue instanceof Object) ||
          !('layoutItems' in originalValue)
        ) {
          return originalValue;
        }
        if (Array.isArray(originalValue.layoutItems)) {
          originalValue.layoutItems.forEach((obj) => {
            if (!obj || !Array.isArray(obj)) {
              return;
            }
            // modify labels
            if (obj[0].label === 'Web Name') {
              obj[0].label = 'Your name';
            }
            if (obj[0].label === 'Web Email') {
              obj[0].label = 'Your email';
            }
            if (obj[0].label === 'Subject') {
              obj[0].label = 'Your question';
            }
            if (obj[0].label === 'Web Reason') {
              obj[0].label = 'What is this about';
            }
            // filter select options
            if (
              obj[0].fields &&
              obj[0].fields[0] &&
              Array.isArray(obj[0].fields[0].pickListOption)
            ) {
              obj[0].fields[0].pickListOption =
                obj[0].fields[0].pickListOption.filter((option) => {
                  return ![
                    'Professional or Cochlear Partner support',
                    'Connect with a volunteer',
                    'Something else',
                  ].includes(option.value);
                });
            }
          });
        }
      } catch (e) {
        return originalValue;
      }

      return originalValue;
    };
    // end workaround

    if (!window.embedded_svc) {
      const s = document.createElement('script');
      s.setAttribute(
        'src',
        `https://${chatConfig.sfEnv}.my.salesforce.com/embeddedservice/5.0/esw.min.js`,
      );
      s.onload = function onloadOverride() {
        initESW(null, token);
      };
      document.body.appendChild(s);

      window.addEventListener('message', receiveMessage, false);
      function receiveMessage(event) {
        var payload = event.data;

        if (payload && payload.type === 'chasitor.sendMessage') {
          window.embedded_svc.postMessage(
            'chasitor.sendMessage',
            payload.message,
          );
        }
      }
    } else {
      initESW('https://service.force.com', token);
    }

    // returned function will be called on component unmount
    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    activeAgentUpdate(action);

    // returned function will be called on component unmount
    return () => {};
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default ChatSFHC;
