import React from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link, useLocation } from 'react-router-dom';

import ArticleDate from './../custom/ArticleDate/ArticleDate';
import ArticleFeedback from './../custom/ArticleFeedback/ArticleFeedback';
import CategoryLink from './../custom/CategoryLink/CategoryLink';
import DeviceLink from './../custom/DeviceLink/DeviceLink';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const ArticleIssue = (props) => {
  const { fields, params } = props;
  // strip out spaces, replace with hypens and transform to lowercase
  const devicesUrl = params?.device?.replace(/ /g, '-').toLowerCase();
  const location = useLocation();
  const url = location?.pathname?.split(`/${devicesUrl}`)[0];

  const categoryUrl = params?.category
    ?.replace(/ /g, '-')
    .toLowerCase();

  return (
    <div className="columns">
      <div className="column is-three-quarters">
        <article className="article">
          <div className="card">
            <div className="card-content">
              <div className="article__header">
                <div className="article__header__details">
                  <ArticleDate
                    label={fields.dateLabel.value}
                    date={fields.date.value}
                  />

                  <span className="article__header__details__group">
                    <DeviceLink
                      link={`${url}/${devicesUrl}`}
                      device={params.device}
                      trademarkRequired={true}
                    />
                    <CategoryLink
                      link={`${url}/${devicesUrl}/${categoryUrl}`}
                      category={params.category}
                      class={categoryUrl}
                    />
                  </span>
                </div>
                <div className="article__header__title">
                  <RichText
                    tag="h1"
                    className="title"
                    field={fields.heading}
                  />
                </div>
              </div>
              <hr />
              <RichText className="content" field={fields.content} />
            </div>
            <footer className="article__footer">
              <ArticleFeedback
                title={fields.feedbackTitle.value}
                labelPositive={fields.feedbackPositiveLabel.value}
                labelNegative={fields.feedbackNegativeLabel.value}
              />
            </footer>
          </div>
        </article>
      </div>
      <div className="column is-one-quarter">
        <aside className="aside">
          <Text
            className="title is-5"
            tag="h2"
            field={fields.relatedTitle}
          />

          <ul className="menu-list">
            {fields.related.map((element, index) => {
              return (
                <li key={index}>
                  <Link
                    data-ecategory="Related Issues"
                    to={`${element.fields.heading.value.href}`}
                    dangerouslySetInnerHTML={{
                      __html: element.fields.heading.value.text,
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </aside>
      </div>
    </div>
  );
};

ArticleIssue.propTypes = {
  fields: PropTypes.shape({
    /** Description of prop "heading". */
    heading: PropTypes.shape({
      value: PropTypes.string,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
    }),
    dateLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    date: PropTypes.shape({
      value: PropTypes.string,
    }),
    viewLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    solvedLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    feedbackTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
    feedbackPositiveLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    feedbackNegativeLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    relatedTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    category: PropTypes.string,
    device: PropTypes.string,
  }),
};

export default ArticleIssue;
