import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { Link, Redirect } from 'react-router-dom';
import {
  Image,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-react';

import isRecipient from './../../utilities/isRecipient';
import AvailabilityStatus from './../custom/AvailabilityStatus/AvailabilityStatus';
import { authenticationService } from './../../_services';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const Card = (props) => {
  const { fields, params } = props;
  // the yml of the card lists a dependency, this corresponds with a prop defined in Layout.js
  // we want to pass this value to the card that requires it
  const status = params.dependency && params[params.dependency]; // returns the value of the prop
  const statusLabel =
    status === 'false' ? fields.offlineLabel : fields.onlineLabel; // return the text label from the yml
  const classStatus = status === 'false' ? 'is-offline' : 'is-online'; // create the class name
  const comingSoon =
    params.dependency === 'serviceRequests' &&
    params.serviceRequests === 'false';

  // chat button trigger
  function handleClick() {
    // chatbot
    const findChatBtn = document.querySelectorAll(
      '.embeddedServiceHelpButton button.helpButtonEnabled',
    );

    if (window.embedded_svc && findChatBtn.length > 0)
      window.embedded_svc.liveAgentAPI.startChat();

    // miaw
    const findMiawButton = document.querySelectorAll(
      '.embedded-messaging',
    );
    if (window.embeddedservice_bootstrap && findMiawButton.length > 0)
      window.embeddedservice_bootstrap.utilAPI.launchChat();
  }

  function handleDisabled(e) {
    e.preventDefault();
  }

  function handleServiceRequestLink(e) {
    const token = authenticationService.currentUserValue;
    const decoded = token && jwtDecode(token);
    const currentUserAttributes =
      authenticationService.currentUserAttributes;
    const personas =
      currentUserAttributes &&
      JSON.parse(currentUserAttributes).personas;

    const appAccess =
      decoded && decoded['https://www.cochlear.com/app'];
    const countryCode =
      decoded && decoded['https://www.cochlear.com/country_code'];
    const userType =
      decoded && decoded['https://www.cochlear.com/user_type'];
    const locale = decoded && decoded['locale'];
    // logged in user - check token exists and user has access
    // checking persona cookie too. checking for a patient/carer scenario
    if (
      token &&
      appAccess &&
      // checking persona cookie too for carers that have patient/carer scenario
      // because their usertype is carer, they dont have ds app claim
      // make sure user is us and does not allow pr
      countryCode.toLowerCase() === 'us' &&
      (appAccess.includes('ds') ||
        (userType.toLowerCase() === 'carer' && isRecipient(personas)))
    ) {
      return true;
    }

    // logged in user - NO DS CLAIM
    if (token && appAccess) {
      authenticationService.setReferrer('location', 'goBackUrl');
      return (
        <Redirect
          to={{
            pathname: `/us/en/support/not-authorized`,
            state: {
              countryCode: countryCode,
              userType: userType,
              locale: locale,
            },
          }}
        />
      );
    }

    // not logged in
    // apply cookie for referrer as 'service-request' path
    // apply cookie for back/return button as current page url
    authenticationService.setReferrer('sr');
    authenticationService.setReferrer('location', 'goBackUrl');
    return true;
  }

  return (
    <div
      className={`card has-text-centered ${
        params.displayStatus === 'true' ? classStatus : ''
      } ${comingSoon ? 'is-coming-soon' : ''}`}
    >
      {params.displayStatus === 'true' && (
        // text status label, eg: online, available, offline, etc
        // show label: show the text label alongside the red/green dot
        <AvailabilityStatus
          label={statusLabel.value || ''}
          showLabel={true}
          class={classStatus}
        />
      )}

      <div className="card-image">
        <figure className="image">
          <Image media={fields.image} />
        </figure>
      </div>

      <header className="card-header">
        <Text
          tag="p"
          className="card-header-title is-centered"
          field={fields.title}
        />
      </header>

      <div className="card-content">
        <RichText field={fields.content} />
      </div>

      <footer className="card-footer">
        {params.dependency === 'onlineSupport' ? (
          <a
            data-ecategory="Need More Help?"
            href={`${fields.buttonLink.value}`}
            className="call__link include-icon-before"
            disabled={params.onlineSupport === 'false'}
            onClick={
              params.onlineSupport === 'false'
                ? handleDisabled
                : undefined
            }
          >
            {fields.buttonText.value}
          </a>
        ) : params.dependency === 'chat' ? (
          <button
            data-ecategory="Need More Help?"
            disabled={params.chat === 'false'}
            onClick={
              params.chat === 'false' ? handleDisabled : handleClick
            }
            className="sdw__button sdw__button--cochlear-yellow"
          >
            {fields.buttonText.value}
          </button>
        ) : comingSoon ? (
          <a
            data-ecategory="Need More Help?"
            href="/"
            className="sdw__button"
            disabled={params.serviceRequests === 'false'}
            onClick={
              params.serviceRequests === 'false'
                ? handleDisabled
                : undefined
            }
          >
            {statusLabel.value}
          </a>
        ) : (
          <Link
            data-ecategory="Need More Help?"
            to={fields.buttonLink.value}
            className="sdw__button sdw__button--cochlear-yellow"
            onClick={handleServiceRequestLink}
          >
            {fields.buttonText.value}
          </Link>
        )}
      </footer>
    </div>
  );
};

Card.propTypes = {
  fields: PropTypes.shape({
    /** Description of prop "image". */
    image: PropTypes.shape({
      value: PropTypes.object,
    }),
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
    }),
    button: PropTypes.shape({
      value: PropTypes.string,
    }),
    offlineLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    onlineLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    dependency: PropTypes.string,
    displayStatus: PropTypes.string,
  }),
};

export default Card;
