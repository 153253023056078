import React, { useState, useEffect } from 'react';
import axios from 'axios';

import config from './../../../common/config';
import { environment } from './../../../_services';
const baseURL = config.baseURL[environment];

// Set up Google Custom Search Engine (CSE):
// Go to the Google Custom Search Engine page: https://programmablesearchengine.google.com/about/.
// Create a new search engine by following the instructions.
// Once created, you'll get a unique Custom Search Engine ID.
const CSE_ID = '57e46f14a078d411b';

// Get an API Key:
// Go to the Google Cloud Console: https://console.cloud.google.com/.
// Create a new project or select an existing one.
// Enable the "Custom Search API" for your project.
// Generate an API Key for your project.
const API_KEY = 'AIzaSyDSFxEepjQIQZCHExMqxjFL0aO0mIpPe5E';

const Search = ({ callbackToggleSeach }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // Scroll to top on component mount or page change
    document.querySelector('#search_list').scrollIntoView();
  }, [currentPage]);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async (event = undefined) => {
    if (event) event.preventDefault();
    try {
      const response = await axios.get(
        `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${CSE_ID}&q=${query}&start=${
          (currentPage - 1) * 10 + 1
        }`,
      );
      setResults(response.data.items);
      setTotalPages(
        Math.ceil(response.data.searchInformation.totalResults / 10),
      );
      setTotalResults(response.data.searchInformation.totalResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    handleSubmit(); // Fetch results for the new page
  };

  const toggleSearch = () => {
    callbackToggleSeach();
  };

  return (
    <div className="search">
      <div
        className="search__background overlay"
        onClick={toggleSearch}
      />

      <div className="search__wrapper">
        <form className="search__form" onSubmit={handleSubmit}>
          <div className="search__container">
            <input
              type="text"
              value={query}
              onChange={handleChange}
              placeholder="Please enter your search term"
              className="search__form__input"
              autoComplete="off"
            />
            <span className="search__form__underline" tabIndex="-1">
              {query}
            </span>
            <button
              type="submit"
              className="search__form__button include-icon-after icon icon--search"
            >
              <span className="is-sr-only">Search</span>
            </button>
          </div>
        </form>
        <div
          className={`search__list ${
            results && results.length > 0 ? `` : `search__list--empty`
          }`}
        >
          <ul id="search_list">
            {results &&
              results.map((item) => (
                <li key={item.cacheId} className="search__list__item">
                  {/* This method is not needed for prod as there will be a redirect in place */}
                  {/* TODO: Add conditional for prod */}
                  <a
                    href={
                      environment !== 'PRD'
                        ? item.link.replace(
                            'https://support.cochlear.com/us/en/',
                            `${baseURL}us/en/support/`,
                          )
                        : item.link
                    }
                    className="search__list__item__link"
                  >
                    <h3 className="search__list__item__link__title">
                      {item.title}
                    </h3>
                    <p className="search__list__item__link__snippet">
                      {item.snippet}
                    </p>
                  </a>
                </li>
              ))}
          </ul>

          {totalPages > 1 && (
            <div className="search__list__pagination">
              <div className="search__container">
                {currentPage !== 1 && (
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="sdw__button  sdw__button--link sdw__button--link-back"
                  >
                    Previous
                  </button>
                )}

                <span className="pagination__indicator">
                  {/* {renderPagination()} */}
                  {(currentPage - 1) * 10 + 1}
                  {` `}-{` `}
                  {currentPage === totalPages
                    ? totalResults
                    : currentPage * 10}
                  {` out of `}
                  {totalResults}
                </span>
                {currentPage !== totalPages && (
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="sdw__button sdw__button--link"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
